import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { serviceAllMarketingLinks } from '../services/serviceAllMarketingLinks';
import {
  IResponseMarketingLinks,
  MarketingLinkSchema,
} from '../types/marketingLink';
import { IFilterPeriod, ISort } from '@/shared/types/sort';

const initialState: MarketingLinkSchema = {
  allMarketingLinks: {
    foundMarketingLinks: 0,
    marketingLinks: [],
    totalMarketingLinks: 0,
  },
  forRequest: {
    filter: { period: null },
    page: 1,
    search: '',
    sort: { direction: 'DESC', name: 'title' },
    step: 20,
  },
  isLoading: false,
  selectedLinksForMessage: {
    isAllLinksSelected: false,
    selectedLinksIds: [],
  },
};

export const marketingLinkSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(serviceAllMarketingLinks.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        serviceAllMarketingLinks.fulfilled,
        (state, { payload }: PayloadAction<IResponseMarketingLinks>) => {
          state.isLoading = false;
          state.allMarketingLinks = {
            foundMarketingLinks: payload.count,
            marketingLinks: payload.data,
            totalMarketingLinks: payload.total,
          };
        },
      )
      .addCase(serviceAllMarketingLinks.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
  initialState,
  name: 'marketingLink',
  reducers: {
    setFiltersMarketingLinks: (
      state,
      { payload }: PayloadAction<IFilterPeriod>,
    ) => {
      state.forRequest.filter = payload;
    },
    setInitialFilters: state => {
      state.forRequest.filter = initialState.forRequest.filter;
      state.forRequest.search = initialState.forRequest.search;
    },
    setIsAllLinksSelected: (state, action: PayloadAction<boolean>) => {
      state.selectedLinksForMessage.isAllLinksSelected = action.payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setPageMarketingLinks: (state, { payload }: PayloadAction<number>) => {
      state.forRequest.page = payload;
    },
    setSearchMarketingLinks: (state, { payload }: PayloadAction<string>) => {
      state.forRequest.search = payload;
    },
    setSelectedLinksIds: (state, { payload }: PayloadAction<number[]>) => {
      state.selectedLinksForMessage.selectedLinksIds = payload;
    },
    setSortMarketingLinks: (state, { payload }: PayloadAction<ISort>) => {
      state.forRequest.sort = payload;
    },
    setStepMarketingLinks: (state, { payload }: PayloadAction<number>) => {
      state.forRequest.step = payload;
    },
  },
});

export const {
  setFiltersMarketingLinks,
  setInitialFilters,
  setIsLoading,
  setPageMarketingLinks,
  setSearchMarketingLinks,
  setSortMarketingLinks,
  setStepMarketingLinks,
  setIsAllLinksSelected,
  setSelectedLinksIds,
} = marketingLinkSlice.actions;
export const { reducer: marketingLinkReducer } = marketingLinkSlice;

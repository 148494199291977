import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProfile } from '../types/profile';
import { ThunkConfig } from '@/app/providers/StoreProvider';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import { IDateValue } from '@/features/FiltersDatePicker';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

interface ISubscribeCustomerData {
  period: IDateValue;
  productId: number;
  customerId: number;
}

export const subscribeCustomerRequest = createAsyncThunk<
  IProfile,
  ISubscribeCustomerData,
  ThunkConfig<string>
>(
  'profile/subscribeCustomerRequest',
  async (subscribeCustomerData, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
      const response = await extra.api.post<IProfile>(
        urls.customer.subscribeCustomer(subscribeCustomerData.customerId),
        {
          period: subscribeCustomerData.period,
          productId: subscribeCustomerData.productId,
        },
      );

      showSnackbar('Subscription was created!', 'success');

      if (!response.data) throw new Error(response as any);

      return response.data;
    } catch (e: any) {
      console.log(e, 'subscribeCustomerRequest');
      showSnackbar(e?.response?.data?.message, 'error');
      return rejectWithValue('error');
    }
  },
);

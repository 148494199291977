import { createAsyncThunk } from '@reduxjs/toolkit';
import { ITableCustomerResponse } from '../types/customer';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const getAllCustomers = createAsyncThunk<
  ITableCustomerResponse,
  void,
  ThunkConfig<string>
>('usersTable/getUsersByParams', async (_, thunkApi) => {
  const { extra, rejectWithValue, getState, dispatch } = thunkApi;

  const {
    customers: {
      customers: {
        allCustomers: { tableRequest },
      },
    },
  } = getState();

  const currentProductIds = tableRequest.filter.productIds.map(id =>
    Number(id.slice(0, 2)).toString(),
  );

  const dataRequest = {
    ...tableRequest,
    filter: {
      ...tableRequest.filter,
      productIds: currentProductIds,
    },
  };

  try {
    const response = await extra.api.post<ITableCustomerResponse>(
      urls.customer.getAllCustomers,
      dataRequest,
    );

    if (!response.data) throw new Error();

    return response.data;
  } catch (e) {
    console.log(e);
    return rejectWithValue('error');
  }
});

import { StateSchema } from '@/app/providers/StoreProvider';

export const getPartnerIsLoading = (state: StateSchema) =>
  state.partners.isLoadingAllPartners;
export const getErrorPartner = (state: StateSchema) => state.partners.error;

export const getForResponsePartner = (state: StateSchema) =>
  state.partners.allPartners;

export const getForRequestPartner = (state: StateSchema) =>
  state.partners.forRequestAllPartners;
export const getFiltersPartner = (state: StateSchema) =>
  state.partners.forRequestAllPartners.filter;
export const getFiltersPeriodPartner = (state: StateSchema) =>
  state.partners.forRequestAllPartners.filter.period;
export const getPagePartner = (state: StateSchema) =>
  state.partners.forRequestAllPartners.page;
export const getSearchPartner = (state: StateSchema) =>
  state.partners.forRequestAllPartners.search;
export const getSortPartner = (state: StateSchema) =>
  state.partners.forRequestAllPartners.sort;
export const getStepPartner = (state: StateSchema) =>
  state.partners.forRequestAllPartners.step;

export const getFoundPartners = (state: StateSchema) =>
  state.partners.allPartners.foundPartners;
export const getAllPartners = (state: StateSchema) =>
  state.partners.allPartners.partners;
export const getTotalPartner = (state: StateSchema) =>
  state.partners.allPartners.totalPartners;

export const getDetailsPartner = (state: StateSchema) =>
  state.partners.selectedPartner;

export const getPaymentsInvitedUsersIsLoading = (state: StateSchema) =>
  state.partners.isLoadingPaymentsInvitedUsers;
export const getForResponsePaymentsInvitedUsers = (state: StateSchema) =>
  state.partners.allPaymentsInvitedUsers;

export const getForRequestPaymentsInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllPaymentsInvitedUsers;
export const getFiltersPaymentsInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllPaymentsInvitedUsers.filter;
export const getFiltersPeriodPaymentsInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllPaymentsInvitedUsers.filter.period;
export const getPagePaymentsInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllPaymentsInvitedUsers.page;
export const getSearchPaymentsInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllPaymentsInvitedUsers.search;
export const getSortPaymentsInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllPaymentsInvitedUsers.sort;
export const getStepPaymentsInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllPaymentsInvitedUsers.step;

export const getFoundPaymentsInvitedUsers = (state: StateSchema) =>
  state.partners.allPaymentsInvitedUsers.foundPaymentsInvitedUsers;
export const getAllPaymentsInvitedUsers = (state: StateSchema) =>
  state.partners.allPaymentsInvitedUsers.invitedUsers;
export const getTotalPaymentsInvitedUsers = (state: StateSchema) =>
  state.partners.allPaymentsInvitedUsers.totalPaymentsInvitedUsers;

export const getInvitedUsersIsLoading = (state: StateSchema) =>
  state.partners.isLoadingInvitedUsers;
export const getForResponseInvitedUsers = (state: StateSchema) =>
  state.partners.allInvitedUsers;

export const getForRequestInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllInvitedUsers;
export const getFiltersInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllInvitedUsers.filter;
export const getFiltersPeriodInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllInvitedUsers.filter.period;
export const getPageInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllInvitedUsers.page;
export const getSearchInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllInvitedUsers.search;
export const getSortInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllInvitedUsers.sort;
export const getStepInvitedUsers = (state: StateSchema) =>
  state.partners.forRequestAllInvitedUsers.step;

export const getFoundInvitedUsers = (state: StateSchema) =>
  state.partners.allInvitedUsers.foundInvitedUsers;
export const getAllInvitedUsers = (state: StateSchema) =>
  state.partners.allInvitedUsers.invitedUsers;
export const getTotalInvitedUsers = (state: StateSchema) =>
  state.partners.allInvitedUsers.totalInvitedUsers;

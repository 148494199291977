import { useState } from 'react';
import { IDateValuePiece } from '@/features/FiltersDatePicker';
import { Button } from '@/shared/ui/Buttons';
import { GStack, VStack } from '@/shared/ui/Stack';
import {
  ModalChangeSubscription,
  ModalEditUserDetails,
  ModalExtendDateOf,
  ModalSubscribe,
} from '@/widgets/Modals';

export const AllModalsTempPage = () => {
  // Edit User Details
  const [showModalEditUserDetails, setShowModalEditUserDetails] =
    useState(false);

  const toggleModalEditUserDetails = () => {
    setShowModalEditUserDetails(!showModalEditUserDetails);
  };
  const toSubmitModalEditUserDetails = () => {
    console.log('Edit User Details');
  };

  // Extend Date of
  const [showModalExtendDateOf, setShowModalExtendDateOf] = useState(false);

  const toggleModalExtendDateOf = () => {
    setShowModalExtendDateOf(!showModalExtendDateOf);
  };
  const toSubmitModalExtendDateOf = () => {
    console.log('Extend Date of');
  };

  // Change subscription
  const [showModalChangeSubscription, setShowModalChangeSubscription] =
    useState(false);

  const toggleModalChangeSubscription = () => {
    setShowModalChangeSubscription(!showModalChangeSubscription);
  };
  const toSubmitModalChangeSubscription = () => {
    console.log('Change subscription');
  };

  // This will suspend the subscription
  const [showModalBooleanSuspend, setShowModalBooleanSuspend] = useState(false);

  const toggleModalBooleanSuspend = () => {
    setShowModalBooleanSuspend(!showModalBooleanSuspend);
  };

  // This will stop DD for the subscription
  const [showModalBooleanStopDD, setShowModalBooleanStopDD] = useState(false);

  const toggleModalBooleanStopDD = () => {
    setShowModalBooleanStopDD(!showModalBooleanStopDD);
  };

  // Subscribe
  const [showModalSubscribe, setShowModalSubscribe] = useState(false);

  const toggleModalSubscribe = () => {
    setShowModalSubscribe(!showModalSubscribe);
  };

  return (
    <VStack>
      <GStack
        gapC={2}
        gapR={2}
        gtc='1fr 1fr'
        style={{
          background: 'aqua',
          height: 600,
          marginTop: 48,
          padding: 24,
          width: 800,
        }}
      >
        <Button
          typeButton='primary'
          text='Edit User Details'
          onClick={toggleModalEditUserDetails}
        />
        <Button
          typeButton='primary'
          text='Extend Date of'
          onClick={toggleModalExtendDateOf}
        />
        <Button
          typeButton='primary'
          text='Change subscription'
          onClick={toggleModalChangeSubscription}
        />
        <Button
          typeButton='primary'
          text='This will suspend'
          onClick={toggleModalBooleanSuspend}
        />
        <Button
          typeButton='primary'
          text='This will stop DD'
          onClick={toggleModalBooleanStopDD}
        />
        <Button
          typeButton='primary'
          text='Subscribe'
          onClick={toggleModalSubscribe}
        />
      </GStack>
      {!!showModalEditUserDetails && (
        <ModalEditUserDetails
          isOpen={showModalEditUserDetails}
          onSubmit={toSubmitModalEditUserDetails}
          onClose={toggleModalEditUserDetails}
        />
      )}
      {!!showModalExtendDateOf && (
        <ModalExtendDateOf
          isOpen={showModalExtendDateOf}
          onSubmit={toSubmitModalExtendDateOf}
          onClose={toggleModalExtendDateOf}
        />
      )}
      {!!showModalChangeSubscription && (
        <ModalChangeSubscription
          isOpen={showModalChangeSubscription}
          subscriptionId={123123}
          subscriptionDate={
            [
              '2024-04-17T09:35:15.704Z',
              '2024-05-17T09:35:16.000Z',
            ] as unknown as [IDateValuePiece, IDateValuePiece]
          }
          onClose={toggleModalChangeSubscription}
        />
      )}
      {!!showModalSubscribe && (
        <ModalSubscribe
          isOpen={showModalSubscribe}
          onClose={toggleModalSubscribe}
        />
      )}
    </VStack>
  );
};

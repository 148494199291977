import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { serviceAllMessages } from '../services/serviceAllMessages';
import { serviceAllMessagesMarketing } from '../services/serviceAllMessagesMarketing';
import { serviceGetIdMessage } from '../services/serviceGetIdMessage';
import { serviceGetMessage } from '../services/serviceGetMessage';
import { serviceSendMessage } from '../services/serviceSendMessage';
import {
  IMessage,
  IResponseGetIdMessage,
  IResponseMessages,
  MessageSchema,
} from '../types/message';
import { IFilterPeriod, ISort } from '@/shared/types/sort';

const initialState: MessageSchema = {
  marketing: {
    allMessages: {
      foundMessages: 0,
      messages: [],
      totalMessages: 0,
    },
    forRequest: {
      filter: { period: null },
      page: 1,
      search: '',
      sort: { direction: 'DESC', name: 'createdAt' },
      step: 20,
    },
    isLoading: false,
  },
  users: {
    allMessages: {
      foundMessages: 0,
      messages: [],
      totalMessages: 0,
    },
    forRequest: {
      filter: { period: null },
      page: 1,
      search: '',
      sort: { direction: 'DESC', name: 'createdAt' },
      step: 20,
    },
    isLoading: false,
    selectedMessage: {
      author: '',
      buttonTitle: '',
      buttonUrl: '',
      createdAt: '',
      id: 0,
      imageUrl: '',
      text: '',
      title: '',
      users: [],
      usersCount: 0,
    },
  },
};

export const messageSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(serviceAllMessages.pending, state => {
        state.users.error = undefined;
        state.users.isLoading = true;
      })
      .addCase(
        serviceAllMessages.fulfilled,
        (state, { payload }: PayloadAction<IResponseMessages>) => {
          state.users.isLoading = false;
          state.users.allMessages = {
            foundMessages: payload.count,
            messages: payload.data,
            totalMessages: payload.total,
          };
        },
      )
      .addCase(serviceAllMessages.rejected, (state, { payload }) => {
        state.users.isLoading = false;
        state.users.error = payload;
      })
      .addCase(serviceAllMessagesMarketing.pending, state => {
        state.marketing.error = undefined;
        state.marketing.isLoading = true;
      })
      .addCase(
        serviceAllMessagesMarketing.fulfilled,
        (state, { payload }: PayloadAction<IResponseMessages>) => {
          state.marketing.isLoading = false;
          state.marketing.allMessages = {
            foundMessages: payload.count,
            messages: payload.data,
            totalMessages: payload.total,
          };
        },
      )
      .addCase(serviceAllMessagesMarketing.rejected, (state, { payload }) => {
        state.marketing.isLoading = false;
        state.marketing.error = payload;
      })
      .addCase(serviceGetMessage.pending, state => {
        state.users.error = undefined;
        state.users.isLoading = true;
      })
      .addCase(
        serviceGetMessage.fulfilled,
        (state, { payload }: PayloadAction<IMessage>) => {
          state.users.isLoading = false;
          state.users.selectedMessage = payload;
        },
      )
      .addCase(serviceGetMessage.rejected, (state, { payload }) => {
        state.users.isLoading = false;
        state.users.error = payload;
      })
      .addCase(serviceGetIdMessage.pending, state => {
        state.users.error = undefined;
        state.users.isLoading = true;
      })
      .addCase(
        serviceGetIdMessage.fulfilled,
        (state, { payload }: PayloadAction<IResponseGetIdMessage>) => {
          state.users.isLoading = false;
          state.users.selectedMessage.id = payload.messageId;
          state.users.selectedMessage.users = payload.data;
        },
      )
      .addCase(serviceGetIdMessage.rejected, (state, { payload }) => {
        state.users.isLoading = false;
        state.users.error = payload;
      })
      .addCase(serviceSendMessage.pending, state => {
        state.users.error = undefined;
        state.users.isLoading = true;
      })
      .addCase(serviceSendMessage.fulfilled, state => {
        state.users.isLoading = false;
      })
      .addCase(serviceSendMessage.rejected, (state, { payload }) => {
        state.users.isLoading = false;
        state.users.error = payload;
      });
  },
  initialState,
  name: 'message',
  reducers: {
    setFiltersMessages: (state, { payload }: PayloadAction<IFilterPeriod>) => {
      state.users.forRequest.filter = payload;
    },
    setFiltersMessagesMarketing: (
      state,
      { payload }: PayloadAction<IFilterPeriod>,
    ) => {
      state.marketing.forRequest.filter = payload;
    },
    setInitialFilters: state => {
      state.users.forRequest.filter = initialState.users.forRequest.filter;
      state.users.forRequest.search = initialState.users.forRequest.search;
    },
    setInitialFiltersMarketing: state => {
      state.marketing.forRequest.filter =
        initialState.marketing.forRequest.filter;
      state.marketing.forRequest.search =
        initialState.marketing.forRequest.search;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.users.isLoading = payload;
    },
    setIsLoadingMarketing: (state, { payload }: PayloadAction<boolean>) => {
      state.marketing.isLoading = payload;
    },
    setPageMessages: (state, { payload }: PayloadAction<number>) => {
      state.users.forRequest.page = payload;
    },
    setPageMessagesMarketing: (state, { payload }: PayloadAction<number>) => {
      state.marketing.forRequest.page = payload;
    },
    setSearchMessages: (state, { payload }: PayloadAction<string>) => {
      state.users.forRequest.search = payload;
    },
    setSearchMessagesMarketing: (state, { payload }: PayloadAction<string>) => {
      state.marketing.forRequest.search = payload;
    },
    setSortMessages: (state, { payload }: PayloadAction<ISort>) => {
      state.users.forRequest.sort = payload;
    },
    setSortMessagesMarketing: (state, { payload }: PayloadAction<ISort>) => {
      state.marketing.forRequest.sort = payload;
    },
    setStepMessages: (state, { payload }: PayloadAction<number>) => {
      state.users.forRequest.step = payload;
    },
    setStepMessagesMarketing: (state, { payload }: PayloadAction<number>) => {
      state.marketing.forRequest.step = payload;
    },
  },
});

export const {
  setIsLoading,
  setFiltersMessages,
  setPageMessages,
  setSearchMessages,
  setSortMessages,
  setStepMessages,
  setInitialFilters,
  setIsLoadingMarketing,
  setFiltersMessagesMarketing,
  setPageMessagesMarketing,
  setSearchMessagesMarketing,
  setSortMessagesMarketing,
  setStepMessagesMarketing,
  setInitialFiltersMarketing,
} = messageSlice.actions;
export const { reducer: messageReducer } = messageSlice;

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { FC, memo } from 'react';
import cls from './filtersDatePicker.module.css';
import { IDateValue } from '../model/types';
import { classNames } from '@/shared/lib/classNames/classNames';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text/Text';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';

interface IFiltersDatePickerProps {
  className?: string;
  date: IDateValue;
  minDate?: Date;
  onChangeDate: (value: IDateValue) => void;
  allowPartialRange?: boolean;
  disabled?: boolean;
}

export const FiltersDatePicker: FC<IFiltersDatePickerProps> = memo(props => {
  const {
    className,
    date,
    onChangeDate,
    minDate,
    allowPartialRange = false,
    disabled,
  } = props;

  return (
    <VStack
      gap={0.5}
      className={classNames(cls['filters-date-picker'], {}, [className])}
    >
      <Text variant='BodyM'>{'Subscription range'}</Text>
      <DateRangePicker
        allowPartialRange={allowPartialRange}
        value={date}
        calendarClassName={cls['react-daterange-picker__calendar']}
        minDate={new Date(minDate as Date)}
        // selectRange={false}
        // navigationAriaLive={'off'}
        className={cls['react-daterange-picker']}
        disabled={disabled}
        onChange={onChangeDate}
      />
    </VStack>
  );
});

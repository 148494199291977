import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IRequestSendMessage } from '../types/message';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import {
  setIsAllCustomersSelected,
  setSelectedCustomersIds,
} from '@/entities/Customer';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const serviceSendMessage = createAsyncThunk<
  void,
  IRequestSendMessage,
  ThunkConfig<string>
>('messages/serviceSendMessage', async (requestData, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  const {
    messages: {
      users: {
        selectedMessage: { id },
      },
    },
  } = getState();

  try {
    const response = await extra.api.post(
      urls.message.sendMessage(id),
      requestData,
    );
    dispatch(setIsAllCustomersSelected(false));
    dispatch(setSelectedCustomersIds([]));

    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.message, 'error');
    return rejectWithValue(customError.message);
  }
});

import { FC, memo, useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import cls from './sendMessageForm.module.css';
import {
  getDetailsMessage,
  getMessageIsLoading,
  serviceSendMessage,
  serviceSendMessageMarketingLink,
} from '@/entities/Message';
import { SendMessageModal } from '@/features/SendMessageModal';
import {
  getRouteCustomers,
  getRouteDecisions,
  getRouteSendMessage,
} from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { AppImage } from '@/shared/ui/AppImage';
import { Button } from '@/shared/ui/Buttons';
import { Input, TextArea } from '@/shared/ui/Inputs';
import { SiteSpinner } from '@/shared/ui/Loaders';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text/Text';

interface ISendMessageProps {
  className?: string;
}

export const SendMessageForm: FC<ISendMessageProps> = memo(props => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const isUsersSendMessage = useMemo(
    () => pathname === getRouteSendMessage(),
    [pathname],
  );

  const { text, title, imageUrl, buttonTitle, buttonUrl } =
    useAppSelector(getDetailsMessage);
  const isLoadingCustomers = useAppSelector(getMessageIsLoading);

  const [valueTitle, setValueTitle] = useState('');
  const [valueText, setValueText] = useState('');
  const [valueImageUrl, setValueImageUrl] = useState('');
  const [valueButtonTitle, setValueButtonTitle] = useState('');
  const [valueButtonUrl, setValueButtonUrl] = useState('');

  const [openModal, setOpenModal] = useState(false);

  const onChangeTitle = (value: string) => setValueTitle(value);
  const onChangeText = (value: string) => setValueText(value);
  const onChangeImageUrl = (value: string) => setValueImageUrl(value);
  const onChangeButtonTitle = (value: string) => setValueButtonTitle(value);
  const onChangeButtonUrl = (value: string) => setValueButtonUrl(value);

  const propsInputTitle = useMemo(() => {
    if (id) return { defaultValue: title, disabled: Boolean(id) };
    return {
      onChange: onChangeTitle,
      value: valueTitle.replace(/ +/g, ' ').trimStart(),
    };
  }, [id, title, valueTitle]);

  const propsArea = useMemo(() => {
    if (id) return { defaultValue: text, disabled: Boolean(id) };
    return {
      onChange: onChangeText,
      value: valueText.replace(/ +/g, ' ').trimStart(),
    };
  }, [id, text, valueText]);

  const propsInputImageUrl = useMemo(() => {
    if (id) return { defaultValue: imageUrl, disabled: Boolean(id) };
    return {
      onChange: onChangeImageUrl,
      value: valueImageUrl.replace(/ +/g, ' ').trimStart(),
    };
  }, [id, imageUrl, valueImageUrl]);

  const propsInputButtonTitle = useMemo(() => {
    if (id) return { defaultValue: buttonTitle, disabled: Boolean(id) };
    return {
      onChange: onChangeButtonTitle,
      value: valueButtonTitle.replace(/ +/g, ' ').trimStart(),
    };
  }, [id, buttonTitle, valueButtonTitle]);

  const propsInputButtonUrl = useMemo(() => {
    if (id) return { defaultValue: buttonUrl, disabled: Boolean(id) };
    return {
      onChange: onChangeButtonUrl,
      value: valueButtonUrl.replace(/ +/g, ' ').trimStart(),
    };
  }, [id, buttonUrl, valueButtonUrl]);

  const handleOpenModal = () => setOpenModal(true);
  const closeModal = () => setOpenModal(false);

  const onSend = useCallback(async () => {
    const currentRequest = isUsersSendMessage
      ? serviceSendMessage
      : serviceSendMessageMarketingLink;

    const response = await dispatch(
      currentRequest({
        buttonTitle: valueButtonTitle || undefined,
        buttonUrl: valueButtonUrl || undefined,
        imageUrl: valueImageUrl || undefined,
        text: valueText,
        title: valueTitle,
      }),
    );
    if (response.meta.requestStatus === 'fulfilled')
      navigation(
        isUsersSendMessage ? getRouteCustomers() : getRouteDecisions(),
      );
  }, [
    isUsersSendMessage,
    dispatch,
    valueButtonTitle,
    valueButtonUrl,
    valueImageUrl,
    valueText,
    valueTitle,
    navigation,
  ]);

  const toCancelMessage = () => navigation(-1);

  const disabledSend = useMemo(
    () =>
      Boolean(id) ||
      valueTitle.length <= 3 ||
      valueText.length <= 5 ||
      (valueButtonTitle.length > 0 && valueButtonUrl.length < 1),
    [
      id,
      valueTitle.length,
      valueText.length,
      valueButtonTitle.length,
      valueButtonUrl.length,
    ],
  );

  const disabledButtonLink = useMemo(
    () => valueButtonTitle.length <= 2,
    [valueButtonTitle.length],
  );

  return (
    <>
      {isLoadingCustomers ? (
        <SiteSpinner />
      ) : (
        <VStack
          max
          className={classNames(cls['send-message-form'], {}, [className])}
        >
          <Text variant='Subheading' as='h4' color='#6E6B7B'>
            {'Message'}
          </Text>
          <Input
            isLabelVertical
            wrapperClass={cls['input-wrapper']}
            label='Message title'
            placeholder='Enter message title'
            {...propsInputTitle}
          />
          <TextArea
            wrapperClass={cls['text-area']}
            rows={10}
            label='Message text'
            placeholder='Enter message text'
            {...propsArea}
          />
          <HStack max justify='between'>
            <VStack max={Boolean(!id)}>
              <Input
                isLabelVertical
                wrapperClass={cls['input-wrapper']}
                label='Image link'
                placeholder='Enter image url'
                {...propsInputImageUrl}
              />
              <Input
                isLabelVertical
                wrapperClass={cls['input-wrapper']}
                label='Button name'
                placeholder='Enter button name'
                {...propsInputButtonTitle}
              />
              <Input
                isLabelVertical
                wrapperClass={cls['input-wrapper']}
                label='Button link'
                placeholder='Enter button link'
                disabled={disabledButtonLink}
                {...propsInputButtonUrl}
              />
            </VStack>
            {!!id && !!imageUrl && (
              <div className={cls['preview-image-wrapper']}>
                <AppImage src={imageUrl} className={cls['preview-image']} />
              </div>
            )}
          </HStack>
          <HStack
            max
            justify='end'
            gap={1}
            className={classNames(cls.buttons, {}, [className])}
          >
            <Button
              size='large'
              typeButton='primary'
              text='Send'
              disabled={disabledSend}
              onClick={handleOpenModal}
            />
            <Button
              size='large'
              typeButton='secondary'
              text='Cancel'
              onClick={toCancelMessage}
            />
          </HStack>
        </VStack>
      )}
      <SendMessageModal
        title={valueTitle}
        text={valueText}
        imageUrl={valueImageUrl}
        buttonTitle={valueButtonTitle}
        buttonUrl={valueButtonUrl}
        isOpen={openModal}
        onClose={closeModal}
        onSend={onSend}
      />
    </>
  );
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCustomerTransactionsRequest } from '../services/getCustomerTransactionsRequest';
import { ITransaction, TransactionSchema } from '../types/transaction';

const initialState: TransactionSchema = {
  data: [],
  isLoading: false,
};

export const transactionSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getCustomerTransactionsRequest.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        getCustomerTransactionsRequest.fulfilled,
        (state, { payload }: PayloadAction<ITransaction[]>) => {
          state.isLoading = false;
          state.data = payload;
        },
      )
      .addCase(
        getCustomerTransactionsRequest.rejected,
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        },
      );
  },
  initialState,
  name: 'transaction',
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
  },
});

export const { setIsLoading } = transactionSlice.actions;
export const { reducer: transactionReducer } = transactionSlice;

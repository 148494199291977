import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IAllowedProduct } from '../types/allowedProduct';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const getAllowedProducts = createAsyncThunk<
  IAllowedProduct[],
  number,
  ThunkConfig<string>
>('product/getAllowedProducts', async (customerId, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<IAllowedProduct[]>(
      urls.products.getAllowedProducts(customerId),
    );

    if (!response.data) throw new Error('error getAllowedProducts');

    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.message, 'error');
    return rejectWithValue(customError.message);
  }
});

import { Suspense, memo, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRouters } from './providers/router';
import { pathNoHeaderFooter } from './providers/router/model/routeConfig';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useTheme } from '@/shared/lib/hooks/useTheme/useTheme';
import { SnackbarsContainer } from '@/shared/ui/Snackbars/Snackbars';
import { MainHeader } from '@/widgets/Headers';

function AppUI() {
  const { pathname } = useLocation();
  const { theme } = useTheme();

  const viewHeader: boolean = useMemo(() => {
    const nonHeaderRoutes = !pathNoHeaderFooter().includes(pathname);

    return nonHeaderRoutes;
  }, [pathname]);

  return (
    <div className={classNames('App', {}, [theme])}>
      <Suspense fallback=''>
        {!!viewHeader && <MainHeader />}
        <AppRouters />
        <SnackbarsContainer />
      </Suspense>
    </div>
  );
}
const App = memo(AppUI);
export default App;

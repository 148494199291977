import { StateSchema } from '@/app/providers/StoreProvider';

export const getMessageIsLoading = (state: StateSchema) =>
  state.messages.users.isLoading;
export const getErrorMessage = (state: StateSchema) =>
  state.messages.users.error;

export const getForResponseMessage = (state: StateSchema) =>
  state.messages.users.allMessages;

export const getForRequestMessage = (state: StateSchema) =>
  state.messages.users.forRequest;
export const getFiltersMessage = (state: StateSchema) =>
  state.messages.users.forRequest.filter;
export const getFiltersPeriodMessage = (state: StateSchema) =>
  state.messages.users.forRequest.filter.period;
export const getPageMessage = (state: StateSchema) =>
  state.messages.users.forRequest.page;
export const getSearchMessage = (state: StateSchema) =>
  state.messages.users.forRequest.search;
export const getSortMessage = (state: StateSchema) =>
  state.messages.users.forRequest.sort;
export const getStepMessage = (state: StateSchema) =>
  state.messages.users.forRequest.step;

export const getFoundMessages = (state: StateSchema) =>
  state.messages.users.allMessages.foundMessages;
export const getAllMessages = (state: StateSchema) =>
  state.messages.users.allMessages.messages;
export const getTotalMessage = (state: StateSchema) =>
  state.messages.users.allMessages.totalMessages;

export const getDetailsMessage = (state: StateSchema) =>
  state.messages.users.selectedMessage;
export const getUsersDetailsMessage = (state: StateSchema) =>
  state.messages.users.selectedMessage.users;

export const getMessageIsLoadingMarketing = (state: StateSchema) =>
  state.messages.marketing.isLoading;
export const getErrorMessageMarketing = (state: StateSchema) =>
  state.messages.marketing.error;

export const getForResponseMessageMarketing = (state: StateSchema) =>
  state.messages.marketing.allMessages;

export const getForRequestMessageMarketing = (state: StateSchema) =>
  state.messages.marketing.forRequest;
export const getFiltersMessageMarketing = (state: StateSchema) =>
  state.messages.marketing.forRequest.filter;
export const getFiltersPeriodMessageMarketing = (state: StateSchema) =>
  state.messages.marketing.forRequest.filter.period;
export const getPageMessageMarketing = (state: StateSchema) =>
  state.messages.marketing.forRequest.page;
export const getSearchMessageMarketing = (state: StateSchema) =>
  state.messages.marketing.forRequest.search;
export const getSortMessageMarketing = (state: StateSchema) =>
  state.messages.marketing.forRequest.sort;
export const getStepMessageMarketing = (state: StateSchema) =>
  state.messages.marketing.forRequest.step;

export const getFoundMessagesMarketing = (state: StateSchema) =>
  state.messages.marketing.allMessages.foundMessages;
export const getAllMessagesMarketing = (state: StateSchema) =>
  state.messages.marketing.allMessages.messages;
export const getTotalMessageMarketing = (state: StateSchema) =>
  state.messages.marketing.allMessages.totalMessages;

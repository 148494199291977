import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IRequestSendMessage } from '../types/message';
import { ThunkConfig } from '@/app/providers/StoreProvider';

import {
  setIsAllLinksSelected,
  setSelectedLinksIds,
} from '@/entities/MarketingLink';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const serviceSendMessageMarketingLink = createAsyncThunk<
  void,
  IRequestSendMessage,
  ThunkConfig<string>
>('messages/serviceSendMessageMarketingLink', async (requestData, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  const {
    marketingLinks: {
      selectedLinksForMessage: { isAllLinksSelected, selectedLinksIds },
    },
  } = getState();

  try {
    const response = await extra.api.post(
      urls.message.sendMessageMarketingLink,
      {
        ...requestData,
        allSelected: isAllLinksSelected,
        channelIds: selectedLinksIds,
      },
    );
    dispatch(setIsAllLinksSelected(false));
    dispatch(setSelectedLinksIds([]));

    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.message, 'error');
    return rejectWithValue(customError.message);
  }
});

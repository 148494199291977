import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProfile } from '../types/profile';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

interface IEditCustomerData {
  name?: string;
  email?: string;
  phoneNumber?: string;
  language?: string;
  timeZone?: string;
  telegramId?: string;
  userId: number;
}

export const editCustomerProfileRequest = createAsyncThunk<
  IProfile,
  IEditCustomerData,
  ThunkConfig<string>
>('profile/editCustomerProfileRequest', async (editCustomerData, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.patch<IProfile>(
      urls.customer.getOrEditCustomerProfile(editCustomerData.userId),
      {
        email: editCustomerData?.email,
        language: editCustomerData?.language,
        name: editCustomerData?.name,
        phoneNumber: editCustomerData?.phoneNumber,
        telegramId: Number(editCustomerData?.telegramId),
        timeZone: editCustomerData?.timeZone,
      },
    );

    if (!response.data) throw new Error(response as any);

    return response.data;
  } catch (e: any) {
    console.log(e, 'error editCustomerProfileRequest');
    showSnackbar(e?.response?.data?.message?.[0], 'error');

    return rejectWithValue(e.response.data.message[0]);
  }
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchActiveProducts } from '../../model/services/activeProducts';
import { fetchAllProducts } from '../../model/services/allProducts';
import { serviceAllCustomersProducts } from '../services/product';
import {
  IProduct,
  IProductActive,
  IProductFilters,
  ProductsSchema,
} from '../types/product';

const initialState: ProductsSchema = {
  activeProducts: [],
  allProducts: [],
  customersProducts: [],
  isLoading: false,
};

export const productSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(serviceAllCustomersProducts.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        serviceAllCustomersProducts.fulfilled,
        (state, { payload }: PayloadAction<IProduct[]>) => {
          state.isLoading = false;
          state.customersProducts = payload;
        },
      )
      .addCase(serviceAllCustomersProducts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(fetchAllProducts.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        fetchAllProducts.fulfilled,
        (state, { payload }: PayloadAction<IProductFilters[]>) => {
          state.isLoading = false;
          state.allProducts = payload;
        },
      )
      .addCase(fetchAllProducts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(fetchActiveProducts.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        fetchActiveProducts.fulfilled,
        (state, { payload }: PayloadAction<IProductActive[]>) => {
          state.isLoading = false;
          state.activeProducts = payload;
        },
      )
      .addCase(fetchActiveProducts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      });
  },
  initialState,
  name: 'product',
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
  },
});

export const { setIsLoading } = productSlice.actions;
export const { reducer: ProductReducer } = productSlice;

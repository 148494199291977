import { ComponentProps, ElementType, ReactNode, useMemo } from 'react';
import cls from './text.module.scss';
import { classNames } from '@/shared/lib/classNames/classNames';

type Variants =
  | 'H1'
  | 'H2'
  | 'H2-Light'
  | 'H3'
  | 'Subheading'
  | 'BodyL'
  | 'BodyL-Light'
  | 'BodyM'
  | 'BodyS'
  | 'Table'
  | 'Button'
  | 'Button-CL'
  | 'Input1'
  | 'Input2';

const selectedVariantClass: Record<Variants, string> = {
  BodyL: cls.BodyL,
  'BodyL-Light': cls['BodyL-Light'],
  BodyM: cls.BodyM,
  BodyS: cls.BodyS,
  Button: cls.Button,
  'Button-CL': cls['Button-CL'],
  H1: cls.H1,
  H2: cls.H2,
  'H2-Light': cls['H2-Light'],
  H3: cls.H3,
  Input1: cls.Input1,
  Input2: cls.Input2,
  Subheading: cls.Subheading,
  Table: cls.Table,
};

type TagName =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'strong'
  | 'em'
  | 'label'
  | 'sub';

type TextOwnProps<T extends ElementType = ElementType> = {
  as?: T;
  variant: Variants;
  children?: ReactNode;
  wrap?: 'wrap' | 'nowrap';
  color?: string;
  align?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify';
};

type ITextProps<T extends ElementType> = TextOwnProps<T> &
  Omit<ComponentProps<T>, keyof TextOwnProps>;

export const Text = <T extends ElementType = TagName>(props: ITextProps<T>) => {
  const {
    as = 'p',
    children,
    align,
    className,
    variant,
    wrap = 'wrap',
    style,
    color,
    ...otherProps
  } = props;

  const TagName = as;

  const currentClass = useMemo(
    () =>
      classNames(cls.text, { [cls.nowrap]: wrap === 'nowrap' }, [
        className,
        selectedVariantClass[variant],
      ]),
    [className, variant, wrap],
  );

  return (
    <TagName
      className={currentClass}
      {...otherProps}
      style={{ color, textAlign: align, ...style }}
    >
      {children}
    </TagName>
  );
};

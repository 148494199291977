import { StateSchema } from '@/app/providers/StoreProvider';

export const getSCVFormatIsLoading = (state: StateSchema) =>
  state.SCVFormat.isLoading;
export const getErrorSCVFormat = (state: StateSchema) => state.SCVFormat?.error;
export const getSCVFormat = (state: StateSchema) => state.SCVFormat.data;

// Activity
export const getCustomerActivityLogsData = (state: StateSchema) =>
  state.SCVFormat.activityData;

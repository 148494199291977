import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  credentials: 'include',
  prepareHeaders: headers => {
    headers.set('Content-Type', 'application/json');
    headers.set('Accept', 'application/json, text/plain, */*');

    const accessToken = localStorage.getItem('USER_AUTH_TOKEN');
    if (accessToken) headers.set('Authorization', `Bearer ${accessToken}`);

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  // const { dispatch } = api;
  // if (result.error?.status === 401) {
  //   dispatch({ type: 'USER_LOGOUT' });
  //   localStorage.clear();
  //   await baseQuery('auth/exit', api, extraOptions);
  // }
  return result;
};

export const rtkApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({}),
  reducerPath: 'rtkApi',
});

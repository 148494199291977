import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { serviceAllInvitedUsers } from '../services/serviceAllInvitedUsers';
import { serviceAllPartners } from '../services/serviceAllPartners';
import { serviceGetPartner } from '../services/serviceGetPartner';
import { servicePaymentsInvitedUsers } from '../services/servicePaymentsInvitedUsers';
import {
  IPartner,
  IPartnerSchema,
  IResponseGetAllPaymentsInvitedUser,
  IResponseGetAllPartners,
  IResponseGetAllInvitedUser,
} from '../types/partner';
import { IFilterPeriod, ISort } from '@/shared/types/sort';

const initialState: IPartnerSchema = {
  allInvitedUsers: {
    foundInvitedUsers: 0,
    invitedUsers: [],
    totalInvitedUsers: 0,
  },
  allPartners: {
    foundPartners: 0,
    partners: [],
    totalPartners: 0,
  },
  allPaymentsInvitedUsers: {
    foundPaymentsInvitedUsers: 0,
    invitedUsers: [],
    totalPaymentsInvitedUsers: 0,
  },
  forRequestAllInvitedUsers: {
    filter: { period: null },
    page: 1,
    search: '',
    sort: { direction: 'DESC', name: 'subscriptionPaidAt' },
    step: 20,
  },
  forRequestAllPartners: {
    filter: { period: null },
    page: 1,
    search: '',
    sort: { direction: 'DESC', name: 'createdAt' },
    step: 20,
  },
  forRequestAllPaymentsInvitedUsers: {
    filter: { period: null },
    page: 1,
    search: '',
    sort: { direction: 'DESC', name: 'createdAt' },
    step: 20,
  },
  isLoadingAllPartners: false,
  isLoadingInvitedUsers: false,
  isLoadingPaymentsInvitedUsers: false,
  selectedPartner: {
    availablePayment: 0,
    email: '',
    id: 0,
    income: '',
    name: '',
    phoneNumber: '',
    status: 'Бро Джерольда',
    sumUsersPayments: 0,
    usersCount: 0,
  },
};

export const partnerSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(serviceAllPartners.pending, state => {
        state.error = undefined;
        state.isLoadingAllPartners = true;
      })
      .addCase(
        serviceAllPartners.fulfilled,
        (state, { payload }: PayloadAction<IResponseGetAllPartners>) => {
          state.isLoadingAllPartners = false;
          state.allPartners = {
            foundPartners: payload.count,
            partners: payload.data,
            totalPartners: payload.total,
          };
        },
      )
      .addCase(serviceAllPartners.rejected, (state, { payload }) => {
        state.isLoadingAllPartners = false;
        state.error = payload;
      })
      .addCase(serviceGetPartner.pending, state => {
        state.error = undefined;
        state.isLoadingAllPartners = true;
      })
      .addCase(
        serviceGetPartner.fulfilled,
        (state, { payload }: PayloadAction<IPartner>) => {
          state.isLoadingAllPartners = false;
          state.selectedPartner = payload;
        },
      )
      .addCase(serviceGetPartner.rejected, (state, { payload }) => {
        state.isLoadingAllPartners = false;
        state.error = payload;
      })
      .addCase(servicePaymentsInvitedUsers.pending, state => {
        state.error = undefined;
        state.isLoadingPaymentsInvitedUsers = true;
      })
      .addCase(
        servicePaymentsInvitedUsers.fulfilled,
        (
          state,
          { payload }: PayloadAction<IResponseGetAllPaymentsInvitedUser>,
        ) => {
          state.isLoadingPaymentsInvitedUsers = false;
          state.allPaymentsInvitedUsers = {
            foundPaymentsInvitedUsers: payload.count,
            invitedUsers: payload.data,
            totalPaymentsInvitedUsers: payload.total,
          };
        },
      )
      .addCase(servicePaymentsInvitedUsers.rejected, (state, { payload }) => {
        state.isLoadingPaymentsInvitedUsers = false;
        state.error = payload;
      })
      .addCase(serviceAllInvitedUsers.pending, state => {
        state.error = undefined;
        state.isLoadingInvitedUsers = true;
      })
      .addCase(
        serviceAllInvitedUsers.fulfilled,
        (state, { payload }: PayloadAction<IResponseGetAllInvitedUser>) => {
          state.isLoadingInvitedUsers = false;
          state.allInvitedUsers = {
            foundInvitedUsers: payload.count,
            invitedUsers: payload.data,
            totalInvitedUsers: payload.total,
          };
        },
      )
      .addCase(serviceAllInvitedUsers.rejected, (state, { payload }) => {
        state.isLoadingInvitedUsers = false;
        state.error = payload;
      });
  },
  initialState,
  name: 'partner',
  reducers: {
    setFiltersInvitedUsers: (
      state,
      { payload }: PayloadAction<IFilterPeriod>,
    ) => {
      state.forRequestAllInvitedUsers.filter = payload;
    },
    setFiltersPartners: (state, { payload }: PayloadAction<IFilterPeriod>) => {
      state.forRequestAllPartners.filter = payload;
    },
    setFiltersPaymentsInvitedUsers: (
      state,
      { payload }: PayloadAction<IFilterPeriod>,
    ) => {
      state.forRequestAllPaymentsInvitedUsers.filter = payload;
    },
    setInitialFilters: state => {
      state.forRequestAllPartners.filter =
        initialState.forRequestAllPartners.filter;
      state.forRequestAllPartners.search =
        initialState.forRequestAllPartners.search;
    },
    setInitialFiltersInvitedUsers: state => {
      state.forRequestAllInvitedUsers.filter =
        initialState.forRequestAllInvitedUsers.filter;
      state.forRequestAllInvitedUsers.search =
        initialState.forRequestAllInvitedUsers.search;
    },
    setInitialFiltersPaymentsInvitedUsers: state => {
      state.forRequestAllPaymentsInvitedUsers.filter =
        initialState.forRequestAllPaymentsInvitedUsers.filter;
      state.forRequestAllPaymentsInvitedUsers.search =
        initialState.forRequestAllPaymentsInvitedUsers.search;
    },
    setPageInvitedUsers: (state, { payload }: PayloadAction<number>) => {
      state.forRequestAllInvitedUsers.page = payload;
    },
    setPagePartners: (state, { payload }: PayloadAction<number>) => {
      state.forRequestAllPartners.page = payload;
    },
    setPagePaymentsInvitedUsers: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.forRequestAllPaymentsInvitedUsers.page = payload;
    },
    setSearchInvitedUsers: (state, { payload }: PayloadAction<string>) => {
      state.forRequestAllInvitedUsers.search = payload;
    },
    setSearchPartners: (state, { payload }: PayloadAction<string>) => {
      state.forRequestAllPartners.search = payload;
    },
    setSearchPaymentsInvitedUsers: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.forRequestAllPaymentsInvitedUsers.search = payload;
    },
    setSortInvitedUsers: (state, { payload }: PayloadAction<ISort>) => {
      state.forRequestAllInvitedUsers.sort = payload;
    },
    setSortPartners: (state, { payload }: PayloadAction<ISort>) => {
      state.forRequestAllPartners.sort = payload;
    },
    setSortPaymentsInvitedUsers: (state, { payload }: PayloadAction<ISort>) => {
      state.forRequestAllPaymentsInvitedUsers.sort = payload;
    },
    setStepInvitedUsers: (state, { payload }: PayloadAction<number>) => {
      state.forRequestAllInvitedUsers.step = payload;
    },
    setStepPartners: (state, { payload }: PayloadAction<number>) => {
      state.forRequestAllPartners.step = payload;
    },
    setStepPaymentsInvitedUsers: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.forRequestAllPaymentsInvitedUsers.step = payload;
    },
  },
});

export const {
  setFiltersPartners,
  setPagePartners,
  setSearchPartners,
  setSortPartners,
  setStepPartners,
  setInitialFilters,
  setFiltersPaymentsInvitedUsers,
  setInitialFiltersPaymentsInvitedUsers,
  setPagePaymentsInvitedUsers,
  setSearchPaymentsInvitedUsers,
  setSortPaymentsInvitedUsers,
  setStepPaymentsInvitedUsers,
  setFiltersInvitedUsers,
  setInitialFiltersInvitedUsers,
  setPageInvitedUsers,
  setSearchInvitedUsers,
  setSortInvitedUsers,
  setStepInvitedUsers,
} = partnerSlice.actions;
export const { reducer: partnerReducer } = partnerSlice;

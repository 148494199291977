import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import { serviceAllCustomersProducts } from '@/entities/Product';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

interface ISuspendCustomerParams {
  customerId: number;
  subscriptionId: number;
}

export const suspendCustomerSubscribeRequest = createAsyncThunk<
  void,
  ISuspendCustomerParams,
  ThunkConfig<string>
>(
  'profile/suspendCustomerSubscribeRequest',
  async (suspendCustomerParams, thunkApi) => {
    const { extra, rejectWithValue, dispatch } = thunkApi;
    const { customerId, subscriptionId } = suspendCustomerParams;
    try {
      const response = await extra.api.post(
        urls.products.suspendCustomerProduct(customerId, subscriptionId),
      );

      showSnackbar('Subscription was successfully disabled', 'success');

      dispatch(serviceAllCustomersProducts(customerId));

      return response.data;
    } catch (error) {
      console.log(error);
      const customError = error as AxiosError<any>;
      showSnackbar(customError.response?.data.message, 'error');
      return rejectWithValue(customError.message);
    }
  },
);

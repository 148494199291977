import {
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  memo,
  ReactNode,
} from 'react';
import cls from './gStack.module.scss';
import {
  GridItems,
  GridContent,
  GridDirection,
} from '../../model/types/typesStacks';
import { classNames, Mods } from '@/shared/lib/classNames/classNames';

const justifyItemsClass: Record<GridItems, string> = {
  center: cls.justifyItemsCenter,
  end: cls.justifyItemsEnd,
  start: cls.justifyItemsStart,
  stretch: cls.justifyItemsStretch,
};
const justifyContentClass: Record<GridContent, string> = {
  around: cls.justifyContentAround,
  between: cls.justifyContentBetween,
  center: cls.justifyContentCenter,
  end: cls.justifyContentEnd,
  evenly: cls.justifyContentEvenly,
  start: cls.justifyContentStart,
  stretch: cls.justifyContentStretch,
};

const alignItemsClass: Record<GridItems, string> = {
  center: cls.alignItemsCenter,
  end: cls.alignItemsEnd,
  start: cls.alignItemsStart,
  stretch: cls.alignItemsStretch,
};

const alignContentClass: Record<GridContent, string> = {
  around: cls.alignContentAround,
  between: cls.alignContentBetween,
  center: cls.alignContentCenter,
  end: cls.alignContentEnd,
  evenly: cls.alignContentEvenly,
  start: cls.alignContentStart,
  stretch: cls.alignContentStretch,
};

const directionClass: Record<GridDirection, string> = {
  column: cls.directionColumn,
  dense: cls.directionDense,
  row: cls.directionRow,
};

export interface GridProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * align-content
   */
  alignContent?: GridContent;
  /**
   * align-item
   */
  alignItem?: GridItems;
  children: ReactNode;
  className?: string;
  /**
   * grid-auto-flow
   */
  direction?: GridDirection;
  /**
   * grid-template-rows
   */
  gtr?: string;
  /**
   * grid-template-columns
   */
  gtc?: string;
  /**
   * grid-template-areas
   */
  gta?: string;
  /**
   * column-gap
   */
  gapC?: number | string;
  /**
   * row-gap
   */
  gapR?: number | string;
  /**
   * justify-item
   */
  justifyItem?: GridItems;
  /**
   * justify-content
   */
  justifyContent?: GridContent;
  /**
   * width: 100%
   */
  max?: boolean;
  style?: CSSProperties;
}

const GStackUi = forwardRef<RefDiv, GridProps>((props, ref) => {
  const {
    alignContent = 'start',
    alignItem = 'start',
    children,
    className,
    direction = 'row',
    gtr,
    gtc,
    gta,
    gapC,
    gapR,
    justifyItem = 'start',
    justifyContent = 'start',
    max,
    style,
    ...otherProps
  } = props;

  const classes = [
    alignContentClass[alignContent],
    alignItemsClass[alignItem],
    directionClass[direction],
    justifyContentClass[justifyContent],
    justifyItemsClass[justifyItem],
    className,
  ];

  const mods: Mods = {
    [cls.max]: max,
  };

  const styles: CSSProperties = {
    columnGap: typeof gapC === 'number' ? `${gapC}rem` : gapC,
    gridTemplateAreas: gta,
    gridTemplateColumns: gtc,
    gridTemplateRows: gtr,
    rowGap: typeof gapR === 'number' ? `${gapR}rem` : gapR,
    ...style,
  };

  return (
    <div
      ref={ref}
      style={styles}
      className={classNames(cls.grid, mods, classes)}
      {...otherProps}
    >
      {children}
    </div>
  );
});

export const GStack = memo(GStackUi);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app/providers/StoreProvider';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import { IDateValue } from '@/features/FiltersDatePicker';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

interface IChangePeriodData {
  period: IDateValue[];
  customerId: number;
  subscriptionId: number;
}

export const changePeriodRequest = createAsyncThunk<
  null,
  IChangePeriodData,
  ThunkConfig<string>
>('profile/switchSubscription', async (changePeriodData, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.patch<null>(
      urls.products.changePeriod(
        changePeriodData.customerId,
        changePeriodData.subscriptionId,
      ),
      { period: changePeriodData.period },
    );

    if (!response.data) throw new Error(response as any);

    return response.data;
  } catch (e: any) {
    console.log(e, 'switchSubscription');
    showSnackbar(e?.response?.data?.message, 'error');
    return rejectWithValue('error');
  }
});

import { FC, memo, useCallback } from 'react';
import { getLogsCsvById } from '../../model/services/getLogsCsvById';
import { getUserRole } from '@/entities/User';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { Button } from '@/shared/ui/Buttons';

interface ISCVButtonProps {
  id: number;
  nameButton?: string;
}

export const SCVButton: FC<ISCVButtonProps> = memo(props => {
  const { id, nameButton = 'Logs' } = props;
  const dispatch = useAppDispatch();
  const userRole = useAppSelector(getUserRole);

  const isAdminOrMarketing = userRole === 'ADMIN' || userRole === 'MANAGER';

  const toDownloadCsv = useCallback(() => {
    dispatch(getLogsCsvById(id));
  }, [dispatch, id]);

  return (
    !!isAdminOrMarketing && (
      <Button size='small' onClick={toDownloadCsv}>
        {nameButton}
      </Button>
    )
  );
});

import { IRole } from '@/entities/User';
import { AppRoutes } from '@/shared/const/router';

export const blackListRoute: { [key in IRole]: string[] } = {
  ADMIN: [],
  MANAGER: [
    AppRoutes.MESSAGE_DETAILS_ROUTE,
    AppRoutes.DECISIONS_ROUTE,
    AppRoutes.SEND_MESSAGE_ROUTE,
    AppRoutes.ALL_USERS_MESSAGES_ROUTE,
    AppRoutes.PARTNERS_ROUTE,
    AppRoutes.PARTNER_DETAILS_ROUTE,
  ],
  MARKETING: [
    AppRoutes.CUSTOMER_BY_ID,
    AppRoutes.PARTNERS_ROUTE,
    AppRoutes.PARTNER_DETAILS_ROUTE,
  ],
  PARTNER_MANAGER: [
    AppRoutes.MESSAGE_DETAILS_ROUTE,
    AppRoutes.DECISIONS_ROUTE,
    AppRoutes.SEND_MESSAGE_ROUTE,
    AppRoutes.ALL_USERS_MESSAGES_ROUTE,
    AppRoutes.CUSTOMER_BY_ID,
  ],
  TEST: [],
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAllCustomers } from '../services/getAllCustomers';
import { CustomerSchema, ITableCustomerResponse } from '../types/customer';
import { ISort } from '@/shared/types/sort';

const initialState: CustomerSchema = {
  customers: {
    allCustomers: {
      tableRequest: {
        filter: {
          customerStatuses: [],
          directDebits: [],
          paymentTypes: [],
          period: null,
          productIds: [],
          subscriptionStatuses: [],
        },
        page: 1,
        search: '',
        size: 20,
        sort: { direction: 'DESC', name: 'createdAt' },
      },
      tableResponse: { count: 0, data: [], total: 0 },
    },
    customerById: {
      billing: '',
      createdAt: '',
      email: '',
      id: 0,
      name: '',
      subscriptionEndDate: '',
      subscriptions: [],
      userStatus: '',
    },
    selectedCustomersForMessage: {
      isAllCustomersSelected: false,
      selectedCustomersIds: [],
    },
  },
  isLoading: false,
};

export const customerSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getAllCustomers.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        getAllCustomers.fulfilled,
        (state, action: PayloadAction<ITableCustomerResponse>) => {
          state.isLoading = false;
          state.customers.allCustomers.tableResponse = action.payload;
        },
      )
      .addCase(getAllCustomers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
  initialState,
  name: 'customer',
  reducers: {
    resetFiltersValue: state => {
      state.customers.allCustomers.tableRequest.filter =
        initialState.customers.allCustomers.tableRequest.filter;
      state.customers.allCustomers.tableRequest.search =
        initialState.customers.allCustomers.tableRequest.search;
    },
    setCurrentPage: (state, { payload }: PayloadAction<number>) => {
      state.customers.allCustomers.tableRequest.page = payload;
    },
    setFiltersValue: (
      state,
      action: PayloadAction<{
        filterKey: string;
        value: string[] | string;
      }>,
    ) => {
      const { filterKey, value } = action.payload;

      // @ts-ignore
      state.customers.allCustomers.tableRequest.filter[filterKey] =
        typeof value === 'string' ? value : value?.filter(val => Boolean(val));
    },
    setIsAllCustomersSelected: (state, action: PayloadAction<boolean>) => {
      state.customers.selectedCustomersForMessage.isAllCustomersSelected =
        action.payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setPeriod: (state, { payload }: PayloadAction<[string, string]>) => {
      state.customers.allCustomers.tableRequest.filter.period = payload;
    },
    setSearchValue: (state, { payload }: PayloadAction<string>) => {
      state.customers.allCustomers.tableRequest.search = payload;
    },
    setSelectedCustomersIds: (
      state,
      { payload }: PayloadAction<number[] | []>,
    ) => {
      state.customers.selectedCustomersForMessage.selectedCustomersIds =
        payload;
    },
    setSizeOnPage: (state, { payload }: PayloadAction<number>) => {
      state.customers.allCustomers.tableRequest.size = payload;
    },
    setSortTable: (state, { payload }: PayloadAction<ISort>) => {
      state.customers.allCustomers.tableRequest.sort = payload;
    },
  },
});

export const { reducer: customerReducer } = customerSlice;
export const {
  setIsLoading,
  resetFiltersValue,
  setCurrentPage,
  setFiltersValue,
  setIsAllCustomersSelected,
  setPeriod,
  setSearchValue,
  setSelectedCustomersIds,
  setSizeOnPage,
  setSortTable,
} = customerSlice.actions;

import { StateSchema } from '@/app/providers/StoreProvider';

export const getUserError = (state: StateSchema) => state.user?.isErrorUser;

export const getUserIsLoading = (state: StateSchema) =>
  state.user.isLoadingUser;

export const getUserEmail = (state: StateSchema) => state.user.data.email;
export const getUserName = (state: StateSchema) => state.user.data.firstName;
export const getUserId = (state: StateSchema) => state.user.data.id;
export const getUserRole = (state: StateSchema) => state.user.data.role;

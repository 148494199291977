import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IProductFilters } from '../../model/types/product';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const fetchAllProducts = createAsyncThunk<
  IProductFilters[],
  void,
  ThunkConfig<string>
>('product/fetchAllProducts', async (_, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<IProductFilters[]>(
      urls.products.getProducts,
    );

    if (!response.data) throw new Error('error fetchAllProducts');

    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.message, 'error');
    return rejectWithValue(customError.message);
  }
});

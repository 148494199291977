import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const logoutQuery = createAsyncThunk<void, void, ThunkConfig<string>>(
  'logoutQuery',
  async (_, thunkApi) => {
    const { extra, dispatch, rejectWithValue } = thunkApi;

    try {
      await extra.api.get(urls.user.logout);
    } catch (e) {
      console.log(e);
      return rejectWithValue('error');
    }
  },
);

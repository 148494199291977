import { FC, memo, Suspense, useMemo, useState } from 'react';
import cls from './modalExtendDateOf.module.css';
import { FiltersDatePicker, IDateValue } from '@/features/FiltersDatePicker';
import { classNames } from '@/shared/lib/classNames/classNames';
import { Button } from '@/shared/ui/Buttons';
import { Spinner } from '@/shared/ui/Loaders';
import { Modal } from '@/shared/ui/Modal';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text/Text';

export interface IModalExtendDateOfProps {
  className?: string;
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const ModalExtendDateOf: FC<IModalExtendDateOfProps> = memo(props => {
  const { className, isOpen, onClose, onSubmit } = props;
  const [filterDate, setFilterDate] = useState<IDateValue>(null);

  const filterDateForDatePicker = useMemo(
    () => (filterDate ? (filterDate as unknown as IDateValue) : null),
    [filterDate],
  );

  const onChangePeriod = (value: IDateValue) => {
    setFilterDate(value);
  };

  return (
    <Modal
      className={classNames(cls.inner, {}, [className])}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Suspense fallback={<Spinner />}>
        <Text align='center' variant='H2'>
          {'Extend Date of STOCKS'}
        </Text>
        <VStack height justify='between'>
          <FiltersDatePicker
            className={cls.datePicker}
            date={filterDateForDatePicker}
            onChangeDate={onChangePeriod}
          />
          <HStack max justify='end' gap={1} className={cls.buttons}>
            <Button
              size='large'
              typeButton='primary'
              text='Submit'
              onClick={onSubmit}
            />
            <Button
              size='large'
              typeButton='ghost'
              text='Cancel'
              onClick={onClose}
            />
          </HStack>
        </VStack>
      </Suspense>
    </Modal>
  );
});

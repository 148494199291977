import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { blackListRoute } from '../consts/blackListRoute';
import { getUserRole } from '@/entities/User';
import { getRouteCustomers, getRoutePartners } from '@/shared/const/router';
import { useAppSelector } from '@/shared/lib/hooks/redux';

export const RequireRole = (props: { children: JSX.Element }) => {
  const { children } = props;

  const location = useLocation();

  const userRole = useAppSelector(getUserRole);
  const isPartner = userRole === 'PARTNER_MANAGER';

  const isBlackRoute = useMemo(
    () =>
      blackListRoute[userRole].some(
        route => location.pathname.split('/')[1] === route.split('/')[1],
      ),
    [location.pathname, userRole],
  );

  const childrenForRole = useMemo(
    () =>
      isBlackRoute ? (
        <Navigate
          replace
          to={isPartner ? getRoutePartners() : getRouteCustomers()}
          state={{ from: location }}
        />
      ) : (
        children
      ),
    [children, isBlackRoute, isPartner, location],
  );

  return childrenForRole;
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ILoginRequest, IUser } from '../types/user';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const loginByUsername = createAsyncThunk<
  IUser,
  ILoginRequest,
  ThunkConfig<string>
>('loginByUsername', async (authData, thunkApi) => {
  const { extra, dispatch, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.post<IUser>(urls.user.login, authData);

    if (!response.data) throw new Error();

    return response.data;
  } catch (e) {
    console.log(e);
    const customError = e as AxiosError<any>;
    if (
      customError.response?.status === 403 ||
      customError?.response?.status === 404 ||
      customError?.response?.status === 400
    )
      showSnackbar(customError.response?.data.message, 'warning');

    return rejectWithValue('error');
  }
});

import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import cls from './customerList.module.css';
import {
  getMessageIsLoading,
  getUsersDetailsMessage,
  serviceGetIdMessage,
  serviceGetMessage,
} from '@/entities/Message';
import { CheckSVG, UnCheckSVG } from '@/shared/assets/svg/new';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { Icon } from '@/shared/ui/Icon';
import { Spinner } from '@/shared/ui/Loaders';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text/Text';

interface ICustomerListProps {
  className?: string;
}

export const CustomerList: FC<ICustomerListProps> = memo(props => {
  const { className } = props;

  const dispatch = useAppDispatch();
  const customers = useAppSelector(getUsersDetailsMessage);
  const isLoadingCustomers = useAppSelector(getMessageIsLoading);

  const { id } = useParams<{ id: string }>();

  const currentImage = (checked: boolean) => (
    <Icon width={16} height={16} Svg={checked ? CheckSVG : UnCheckSVG} />
  );

  const getMessageDetails = useCallback(
    () => id && dispatch(serviceGetMessage(id)),
    [dispatch, id],
  );

  const getIdMessages = useCallback(
    () => dispatch(serviceGetIdMessage()),
    [dispatch],
  );

  useEffect(() => {
    id ? getMessageDetails() : getIdMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const content = useMemo(() => {
    if (isLoadingCustomers) return <Spinner />;
    if (!!customers && !!customers.length)
      return customers?.map(customer => (
        <HStack
          key={customer.id}
          max
          justify='between'
          className={cls['selected-customer-row']}
        >
          <VStack max>
            <HStack>
              <Text variant='BodyL'>{customer?.name}</Text>
              {customer.isMessageSent !== null &&
                currentImage(customer.isMessageSent)}
            </HStack>
            <Text variant='BodyM' className={cls.email}>
              {customer?.email}
            </Text>
          </VStack>
        </HStack>
      ));
  }, [customers, isLoadingCustomers]);

  return (
    <VStack
      max
      align='center'
      className={classNames(cls['customer-list'], {}, [className])}
    >
      {content}
    </VStack>
  );
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchDecisions } from '../services/fetchDecisions';
import { DecisionsSchema, IDecision } from '../types/decisions';

const initialState: DecisionsSchema = {
  data: { all_products: '', crypto: '', forex: '', stocks: '' },
  isLoading: false,
};

export const decisionsSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(fetchDecisions.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        fetchDecisions.fulfilled,
        (state, action: PayloadAction<IDecision>) => {
          state.isLoading = false;
          state.data = action.payload;
        },
      )
      .addCase(fetchDecisions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
  initialState,
  name: 'decisions',
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
  },
});

export const {
  actions: { setIsLoading },
} = decisionsSlice;
export const { reducer: decisionsReducer } = decisionsSlice;

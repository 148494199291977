import {
  FC,
  memo,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import cls from './modalChangeSubscription.module.css';
import { switchSubscriptionRequest } from '../model/services/switchSubscription';
import { IProductsSelectOption } from '../model/types/types';
import {
  getAllowedProducts,
  IAllowedProduct,
  serviceAllCustomersProducts,
} from '@/entities/Product';

import { FiltersDatePicker, IDateValue } from '@/features/FiltersDatePicker';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { Button } from '@/shared/ui/Buttons';
import { Spinner } from '@/shared/ui/Loaders';
import { Modal } from '@/shared/ui/Modal';
import { Select } from '@/shared/ui/Popovers';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text/Text';

export interface IModalChangeSubscriptionProps {
  className?: string;
  isOpen: boolean;
  subscriptionId: number;
  subscriptionDate: IDateValue;
  onClose: () => void;
}

export const ModalChangeSubscription: FC<IModalChangeSubscriptionProps> = memo(
  props => {
    const { className, isOpen, subscriptionId, subscriptionDate, onClose } =
      props;

    const { id } = useParams();
    const dispatch = useAppDispatch();

    const [filterDate, setFilterDate] = useState<IDateValue>(null);
    const [allowedProducts, setAllowedProducts] = useState<
      IProductsSelectOption[]
    >([]);
    const [subscription, setSubscription] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const filterDateForDatePicker = useMemo(
      () => (filterDate ? (filterDate as unknown as IDateValue) : null),
      [filterDate],
    );

    const onChangePeriod = (value: IDateValue) => {
      setFilterDate(value);
    };

    const onChangeSubscription = (val: any) => {
      setSubscription(val);
    };

    const onSubmitChangeSubscription = async () => {
      const req = await dispatch(
        switchSubscriptionRequest({
          customerId: Number(id),
          productId: Number(subscription.slice(0, 2)),
          subscriptionId,
        }),
      );

      if (req.meta.requestStatus === 'fulfilled') {
        dispatch(serviceAllCustomersProducts(Number(id)));
        onClose();
      }
    };

    const getAllowedCustomerSubs = useCallback(async () => {
      setIsLoading(true);
      const allowedProductsRes = await dispatch(getAllowedProducts(Number(id)));
      setIsLoading(false);

      if (allowedProductsRes.payload) {
        const resultToStateTemp: IProductsSelectOption[] = [];
        const allowed: IAllowedProduct[] =
          allowedProductsRes.payload as IAllowedProduct[];

        if (Array.isArray(allowed))
          allowed?.map((item: IAllowedProduct) =>
            resultToStateTemp.push({
              content: item.title,
              value: `${item.id} ${item.title}`,
            }),
          );

        setAllowedProducts(resultToStateTemp);
        setSubscription(resultToStateTemp[0]?.value);
      }
    }, [dispatch, id]);

    useEffect(() => {
      getAllowedCustomerSubs();
    }, [getAllowedCustomerSubs]);

    return (
      <Modal
        className={classNames(cls.inner, {}, [className])}
        isOpen={isOpen}
        onClose={onClose}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <Suspense fallback={<Spinner />}>
            <Text align='center' variant='H2'>
              {'Change subscription'}
            </Text>
            <VStack height>
              <FiltersDatePicker
                disabled
                className={cls.datePicker}
                date={subscriptionDate}
                onChangeDate={onChangePeriod}
              />
              <Select
                label='Products'
                items={allowedProducts.length ? allowedProducts : []}
                wrapperClass={cls['wrapper-select']}
                className={cls['inner-select']}
                value={allowedProducts.length ? [subscription] : 'No options'}
                readonly={!allowedProducts.length}
                onChange={value => onChangeSubscription(value)}
              />
            </VStack>
            <HStack max justify='end' gap={1} className={cls.buttons}>
              <Button
                size='large'
                typeButton='primary'
                text='Submit'
                disabled={!allowedProducts.length}
                onClick={onSubmitChangeSubscription}
              />
              <Button
                size='large'
                typeButton='ghost'
                text='Cancel'
                onClick={onClose}
              />
            </HStack>
          </Suspense>
        )}
      </Modal>
    );
  },
);

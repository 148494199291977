import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import cls from './sendMessagePage.module.css';
import { serviceGetMessage } from '@/entities/Message';
import { getRouteSendMessage } from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text/Text';
import { CustomerList } from '@/widgets/CustomerList';
import { Page } from '@/widgets/Page';
import { SendMessageForm } from '@/widgets/SendMessageForm';

interface ISendMessagePageProps {
  className?: string;
}

export const SendMessagePage: FC<ISendMessagePageProps> = memo(props => {
  const { className } = props;
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();

  const isUsersSendMessage = useMemo(
    () => pathname === getRouteSendMessage(),
    [pathname],
  );

  const titlePage = useMemo(
    () =>
      isUsersSendMessage
        ? 'Send message to customers, telegram'
        : 'Send message to customers from marketing links',
    [isUsersSendMessage],
  );

  const getMessageDetails = useCallback(
    () => id && dispatch(serviceGetMessage(id)),
    [dispatch, id],
  );

  useEffect(() => {
    id && !isUsersSendMessage && getMessageDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Page
      justify='left'
      align='center'
      className={classNames(cls['send-message-page'], {}, [className])}
    >
      <VStack max className={cls['title-wrapper']}>
        <Text variant='Subheading' as='h4'>
          {titlePage}
        </Text>
      </VStack>
      <HStack max align='start' gap={1}>
        {!!isUsersSendMessage && (
          <VStack
            justify='between'
            className={cls['selected-customers-wrapper']}
          >
            <Text variant='Subheading' as='h4' color='#6E6B7B'>
              {'Selected customers'}
            </Text>
            <CustomerList />
          </VStack>
        )}
        <SendMessageForm />
      </HStack>
    </Page>
  );
});

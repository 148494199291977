import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCustomerActivityLogs } from '../../model/services/fetchCustomerActivityLogs';
import { getLogsCsvById } from '../services/getLogsCsvById';
import { ILogsCSVResponse, SCVFormatSchema } from '../types/SCVFormat';

const initialState: SCVFormatSchema = {
  activityData: {
    data: [],
    fileName: '',
    headers: [{ key: '', label: '' }],
  },
  isLoading: false,
};

export const SCVFormatSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getLogsCsvById.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        getLogsCsvById.fulfilled,
        (state, action: PayloadAction<ILogsCSVResponse>) => {
          state.isLoading = false;
          state.data = action.payload;
        },
      )
      .addCase(getLogsCsvById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCustomerActivityLogs.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        fetchCustomerActivityLogs.fulfilled,
        (state, { payload }: PayloadAction<ILogsCSVResponse>) => {
          state.isLoading = false;
          state.activityData.data = payload.data;
        },
      )
      .addCase(fetchCustomerActivityLogs.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
        state.activityData = initialState.activityData;
      });
  },
  initialState,
  name: 'SCVFormat',
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
  },
});

export const { setIsLoading } = SCVFormatSlice.actions;
export const { reducer: SCVFormatReducer } = SCVFormatSlice;

import axios from 'axios';
import urls from '../const/urls';
import { logoutAxios } from '../lib/api/logoutAxios';

const api = process.env.API_URL || 'http://localhost:3000/';

const app = axios.create({
  baseURL: api,
  headers: {
    Accept: 'application/json, text/plain, text/event-stream, */*',
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
app.interceptors.request.use(
  async config => {
    const accessToken = localStorage.getItem('USER_AUTH_TOKEN');

    accessToken && (config.headers.Authorization = `Bearer ${accessToken}`);

    return config;
  },
  error => Promise.reject(error),
);

app.interceptors.response.use(
  config => config,
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 429) logoutAxios(error);

    if (error.response.status === 401 && error.config && !error.config._isRetry)
      try {
        const response = await app.get(urls.user.getRefreshToken, {
          withCredentials: true,
        });

        localStorage.setItem('USER_AUTH_TOKEN', `${response.data.accessToken}`);
        originalRequest._isRetry = true;

        return app.request(originalRequest);
      } catch (e) {
        logoutAxios(e);
      }

    return Promise.reject(error);
  },
);

export default app;

import {
  ILogsCSVData,
  ILogsCSVHeaders,
  ILogsCSVResponse,
} from '../../model/types/SCVFormat';

const _typeof =
  typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
    ? (obj: string) => typeof obj
    : (obj: string) =>
        obj &&
        typeof Symbol === 'function' &&
        obj.constructor === Symbol &&
        obj !== Symbol.prototype
          ? 'symbol'
          : typeof obj;

const toConsumableArray = (arr: ILogsCSVData[]) => {
  const arr2 = Array(arr.length);
  if (Array.isArray(arr)) {
    for (let i = 0; i < arr.length; i++) arr2[i] = arr[i];

    return arr2;
  }
  return Array.from(arr);
};

const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const isJsons = (array: any) =>
  Array.isArray(array) &&
  array.every(
    row =>
      (typeof row === 'undefined' ? 'undefined' : _typeof(row)) === 'object' &&
      !(row instanceof Array),
  );

const jsonsHeaders = (array: ILogsCSVData[]) =>
  Array.from(
    array
      .map((json: ILogsCSVData) => Object.keys(json))
      .reduce(
        (a: any, b: any) =>
          // @ts-ignore
          new Set([].concat(toConsumableArray(a), toConsumableArray(b))),
        [],
      ),
  );

const getHeaderValue = (property: any, obj: ILogsCSVData[]) => {
  const foundValue = property
    .replace(/\[([^\]]+)]/g, '.$1')
    .split('.')
    .reduce((o: ILogsCSVData[], p: number, i: number, arr: string[]) => {
      const value = o[p];
      if (value === undefined || value === null) return arr.splice(1);
      return value;
    }, obj);

  const propertyInObj = property in obj ? obj[property] : '';

  return foundValue === undefined ? propertyInObj : foundValue;
};

const jsons2arrays = (jsons: ILogsCSVData[], headers: any) => {
  const newHeaders = headers || jsonsHeaders(jsons);
  let headerLabels = newHeaders;
  let headerKeys = newHeaders;
  if (isJsons(newHeaders)) {
    headerLabels = newHeaders.map((header: ILogsCSVHeaders) => header.label);
    headerKeys = newHeaders.map((header: ILogsCSVHeaders) => header.key);
  }
  const data = jsons.map((object: any) =>
    headerKeys.map((header: ILogsCSVHeaders) => getHeaderValue(header, object)),
  );

  return [headerLabels].concat(toConsumableArray(data));
};

const elementOrEmpty = (element: ILogsCSVData) =>
  typeof element === 'undefined' || element === null ? '' : element;

const joiner = (data: ILogsCSVData[], sep: string, close: string) => {
  const separator = sep || ',';
  const enclosingCharacter = close || '"';

  return data
    .filter((e: ILogsCSVData) => e)
    .map((row: any) =>
      row
        .map((element: ILogsCSVData) => elementOrEmpty(element))
        .map(
          (column: string) =>
            `${enclosingCharacter}${column}${enclosingCharacter}`,
        )
        .join(separator),
    )
    .join('\n');
};

const toCSV = (
  data: ILogsCSVData[],
  headers: ILogsCSVHeaders[],
  separator: string,
  enclosingCharacter: string,
) => {
  if (isJsons(data))
    return joiner(jsons2arrays(data, headers), separator, enclosingCharacter);
};

export const buildURI = (props: {
  data: ILogsCSVData[];
  uFEFF?: boolean;
  headers: ILogsCSVHeaders[];
  separator?: string;
  enclosingCharacter?: string;
}) => {
  const {
    data,
    enclosingCharacter = '"',
    headers,
    separator = ',',
    uFEFF,
  } = props;

  const csv = toCSV(data, headers, separator, enclosingCharacter);
  const type = isSafari() ? 'application/csv' : 'text/csv';
  const blob = new Blob([uFEFF ? '\uFEFF' : '', csv as string], { type });
  const dataURI = `data:${type};charset=utf-8,${uFEFF ? '\uFEFF' : ''}${csv}`;

  const URL = window.URL || window.webkitURL;

  return typeof URL.createObjectURL === 'undefined'
    ? dataURI
    : URL.createObjectURL(blob);
};

export const downloadCsvLogs = (data: ILogsCSVResponse) => {
  const a = document.createElement('a');
  a.href = buildURI({ data: data.data, headers: data.headers });
  a.target = '_blank';
  a.download = data.fileName;
  a.click();
  URL.revokeObjectURL(a.href);
  a.remove();
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import { serviceAllCustomersProducts } from '@/entities/Product';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

interface IDdCustomerParams {
  customerId: number;
  subscriptionId: number;
}

export const ddCustomerSubscribeRequest = createAsyncThunk<
  void,
  IDdCustomerParams,
  ThunkConfig<string>
>('profile/ddCustomerSubscribeRequest', async (ddCustomerParams, thunkApi) => {
  const { extra, rejectWithValue, dispatch } = thunkApi;
  const { customerId, subscriptionId } = ddCustomerParams;

  try {
    const response = await extra.api.post(
      urls.products.ddCustomerProduct(customerId, subscriptionId),
    );

    showSnackbar('Direct Debit was successfully deactivated', 'success');

    dispatch(serviceAllCustomersProducts(customerId));

    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.message, 'error');
    return rejectWithValue(customError.message);
  }
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IProduct } from '../types/product';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const serviceAllCustomersProducts = createAsyncThunk<
  IProduct[],
  number,
  ThunkConfig<string>
>('product/serviceAllCustomersProducts', async (customerId, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<IProduct[]>(
      urls.products.getCustomerProduct(customerId),
    );

    if (!response.data) throw new Error('error serviceAllCustomersProducts');

    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.message, 'error');
    return rejectWithValue(customError.message);
  }
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import { serviceAllCustomersProducts } from '@/entities/Product';
import { getCustomerProfileRequest } from '@/entities/Profile';
import { fetchCustomerActivityLogs } from '@/entities/SCVFormat';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const blockCustomer = createAsyncThunk<
  void,
  string,
  ThunkConfig<string>
>('usersTable/getUsersByParams', async (customerId, thunkApi) => {
  const { extra, rejectWithValue, getState, dispatch } = thunkApi;

  try {
    const response = await extra.api.post(
      urls.customer.blockCustomer(customerId),
    );

    showSnackbar('User was blocked successfully', 'success');

    if (response.status > 400) throw new Error('error block customer');

    dispatch(serviceAllCustomersProducts(Number(customerId)));

    dispatch(getCustomerProfileRequest(Number(customerId)));

    dispatch(fetchCustomerActivityLogs(Number(customerId)));
    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.message, 'error');
    return rejectWithValue(customError.message);
  }
});

import { StateSchema } from '@/app/providers/StoreProvider';

export const getMarketingLinkIsLoading = (state: StateSchema) =>
  state.marketingLinks.isLoading;
export const getErrorMarketingLink = (state: StateSchema) =>
  state.marketingLinks.error;

export const getForResponseMarketingLinks = (state: StateSchema) =>
  state.marketingLinks.allMarketingLinks;

export const getForRequestMarketingLinks = (state: StateSchema) =>
  state.marketingLinks.forRequest;
export const getFiltersMarketingLinks = (state: StateSchema) =>
  state.marketingLinks.forRequest.filter;
export const getFiltersPeriodMarketingLinks = (state: StateSchema) =>
  state.marketingLinks.forRequest.filter.period;
export const getPageMarketingLinks = (state: StateSchema) =>
  state.marketingLinks.forRequest.page;
export const getSearchMarketingLinks = (state: StateSchema) =>
  state.marketingLinks.forRequest.search;
export const getSortMarketingLinks = (state: StateSchema) =>
  state.marketingLinks.forRequest.sort;
export const getStepMarketingLinks = (state: StateSchema) =>
  state.marketingLinks.forRequest.step;

export const getFoundMarketingLinks = (state: StateSchema) =>
  state.marketingLinks.allMarketingLinks.foundMarketingLinks;
export const getAllMarketingLinks = (state: StateSchema) =>
  state.marketingLinks.allMarketingLinks.marketingLinks;
export const getTotalMarketingLinks = (state: StateSchema) =>
  state.marketingLinks.allMarketingLinks.totalMarketingLinks;

export const getIsAllMarketingLinksSelected = (state: StateSchema) =>
  state.marketingLinks.selectedLinksForMessage.isAllLinksSelected;
export const getSelectedMarketingLinksIds = (state: StateSchema) =>
  state.marketingLinks.selectedLinksForMessage.selectedLinksIds;

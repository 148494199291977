import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IResponseGetIdMessage } from '../types/message';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const serviceGetIdMessage = createAsyncThunk<
  IResponseGetIdMessage,
  void,
  ThunkConfig<string>
>('usersTable/serviceGetIdMessage', async (_, thunkApi) => {
  const { extra, rejectWithValue, getState } = thunkApi;

  const {
    customers: {
      customers: {
        allCustomers: { tableRequest },
        selectedCustomersForMessage: {
          isAllCustomersSelected,
          selectedCustomersIds,
        },
      },
    },
  } = getState();

  const productIds = tableRequest.filter.productIds.map(id =>
    Number(id.slice(0, 2)).toString(),
  );

  const dataRequest = {
    ...tableRequest,
    allSelected: isAllCustomersSelected,
    filter: { ...tableRequest.filter, productIds },
    userIds: selectedCustomersIds,
  };

  try {
    const response = await extra.api.post<IResponseGetIdMessage>(
      urls.message.getUsersInMessages,
      dataRequest,
    );

    if (!response.data) throw new Error('error serviceGetIdMessage');

    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.message, 'error');
    return rejectWithValue(customError.message);
  }
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IResponseGetAllInvitedUser } from '../types/partner';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const serviceAllInvitedUsers = createAsyncThunk<
  IResponseGetAllInvitedUser,
  string,
  ThunkConfig<string>
>('partner/serviceAllInvitedUsers', async (partnerId, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  const {
    partners: { forRequestAllInvitedUsers },
  } = getState();

  try {
    const response = await extra.api.post<IResponseGetAllInvitedUser>(
      urls.partners.getAllInvitedUsers(partnerId),
      {
        ...forRequestAllInvitedUsers,
        size: forRequestAllInvitedUsers.step,
      },
    );
    if (!response.data) throw new Error('An error occurred');
    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.partner, 'error');
    return rejectWithValue(customError.message);
  }
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IResponseMarketingLinks } from '../types/marketingLink';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const serviceAllMarketingLinks = createAsyncThunk<
  IResponseMarketingLinks,
  void,
  ThunkConfig<string>
>('MarketingLinks/serviceAllMarketingLinks', async (_, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  const {
    marketingLinks: { forRequest },
  } = getState();

  try {
    const response = await extra.api.post<IResponseMarketingLinks>(
      urls.marketingLinks.getAllMarketingLinks,
      { ...forRequest, size: forRequest.step },
    );

    if (!response.data) throw new Error('error serviceAllMarketingLinks');

    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.message, 'error');
    return rejectWithValue(customError.message);
  }
});

import { StateSchema } from '@/app/providers/StoreProvider';

// general
export const getCustomersTableError = (state: StateSchema) =>
  state?.customers.error || '';
export const getCustomersIsLoading = (state: StateSchema) =>
  state?.customers.isLoading || false;

// table response
export const getCustomersTable = (state: StateSchema) =>
  state.customers?.customers?.allCustomers?.tableResponse?.data || [];
export const getCustomersTableCount = (state: StateSchema) =>
  state.customers?.customers?.allCustomers?.tableResponse?.count;
export const getCustomersTableTotalPage = (state: StateSchema) =>
  state.customers?.customers?.allCustomers?.tableResponse?.total;

// filters and search
export const getCustomersTableFiltersPaymentType = (state: StateSchema) =>
  state.customers?.customers?.allCustomers?.tableRequest?.filter?.paymentTypes;
export const getCustomersTableFiltersCustomerStatus = (state: StateSchema) =>
  state.customers?.customers?.allCustomers?.tableRequest?.filter
    ?.customerStatuses;
export const getCustomersTableFiltersProductsType = (state: StateSchema) =>
  state.customers?.customers?.allCustomers?.tableRequest?.filter?.productIds;
export const getCustomersTableFiltersPeriod = (state: StateSchema) =>
  state.customers?.customers?.allCustomers?.tableRequest?.filter?.period;
export const getCustomersTableFiltersDirectDebit = (state: StateSchema) =>
  state.customers?.customers?.allCustomers?.tableRequest?.filter?.directDebits;
export const getCustomersTableFiltersSubscriptionStatus = (
  state: StateSchema,
) =>
  state.customers?.customers?.allCustomers?.tableRequest?.filter
    ?.subscriptionStatuses;
export const getCustomersTableAllFilters = (state: StateSchema) =>
  state.customers?.customers?.allCustomers?.tableRequest?.filter;
export const getCustomersTableSearchValue = (state: StateSchema) =>
  state.customers?.customers?.allCustomers?.tableRequest?.search;
export const getCustomersTableSort = (state: StateSchema) =>
  state.customers?.customers?.allCustomers?.tableRequest?.sort;

// customers selection
export const getIsAllCustomersSelected = (state: StateSchema) =>
  state?.customers?.customers?.selectedCustomersForMessage
    ?.isAllCustomersSelected;
export const getSelectedCustomersIds = (state: StateSchema) =>
  state?.customers?.customers?.selectedCustomersForMessage
    ?.selectedCustomersIds;

// pagination
export const getCustomersTableCurrentPage = (state: StateSchema) =>
  state?.customers?.customers?.allCustomers?.tableRequest?.page;
export const getCustomersTableSizePage = (state: StateSchema) =>
  state?.customers?.customers?.allCustomers?.tableRequest?.size;

import { AllMarketingMessagesPage } from '@/pages/AllMarketingMessagesPage';
import { AllMessagesPage } from '@/pages/AllMessagesPage';
import { AllModalsTempPage } from '@/pages/AllModalsTempPage';
import { AllPartnersPage } from '@/pages/AllPartnersPage';
import { CustomerPage } from '@/pages/CustomerPage';
import { CustomersPage } from '@/pages/CustomersPage';
import { DecisionsPage } from '@/pages/DecisionsPage';
import { LoginPage } from '@/pages/LoginPage';
import { NotFoundPage } from '@/pages/NotFoundPage';
import { PartnerPage } from '@/pages/PartnerPage';
import { SendMessagePage } from '@/pages/SendMessagePage';
import { AppRoutes, allRoutes } from '@/shared/const/router';
import { AppRoutesProps } from '@/shared/types/router';

const {
  getRouteDecisions,
  getRouteLogin,
  getRouteCustomer,
  getRouteCustomers,
  getRouteSendMessage,
  getRouteAllMessagesUsers,
  getRouteMessagesDetails,
  getRoutePartners,
  getRoutePartnersDetails,
  getRouteMarketingLinkDetails,
  getRouteSendMessageLinks,
  getRouteAllMessagesMarketing,
} = allRoutes;

export const routeConfigs: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.LOGIN_ROUTE]: {
    authOnly: false,
    element: <LoginPage />,
    noHeaderFooter: true,
    path: getRouteLogin(),
  },
  [AppRoutes.CUSTOMER_BY_ID]: {
    authOnly: true,
    element: <CustomerPage />,
    noHeaderFooter: true,
    path: getRouteCustomer(':id'),
  },
  [AppRoutes.NOT_FOUND]: {
    element: <NotFoundPage />,
    path: '*',
  },
  [AppRoutes.MODALS]: {
    element: <AllModalsTempPage />,
    path: '/modals',
  },
  [AppRoutes.DECISIONS_ROUTE]: {
    authOnly: true,
    element: <DecisionsPage />,
    path: getRouteDecisions(),
  },
  [AppRoutes.CUSTOMERS_ROUTE]: {
    authOnly: true,
    element: <CustomersPage />,
    path: getRouteCustomers(),
  },
  [AppRoutes.SEND_MESSAGE_ROUTE]: {
    authOnly: true,
    element: <SendMessagePage />,
    path: getRouteSendMessage(),
  },
  [AppRoutes.SEND_MESSAGE_LINKS_ROUTE]: {
    authOnly: true,
    element: <SendMessagePage />,
    path: getRouteSendMessageLinks(),
  },
  [AppRoutes.MESSAGE_DETAILS_ROUTE]: {
    authOnly: true,
    element: <SendMessagePage />,
    path: getRouteMessagesDetails(':id'),
  },
  [AppRoutes.ALL_USERS_MESSAGES_ROUTE]: {
    authOnly: true,
    element: <AllMessagesPage />,
    path: getRouteAllMessagesUsers(),
  },
  [AppRoutes.ALL_MARKETING_MESSAGES_ROUTE]: {
    authOnly: true,
    element: <AllMarketingMessagesPage />,
    path: getRouteAllMessagesMarketing(),
  },
  [AppRoutes.PARTNERS_ROUTE]: {
    authOnly: true,
    element: <AllPartnersPage />,
    path: getRoutePartners(),
  },
  [AppRoutes.PARTNER_DETAILS_ROUTE]: {
    authOnly: true,
    element: <PartnerPage />,
    path: getRoutePartnersDetails(':id'),
  },
  [AppRoutes.MARKETING_LINK_DETAILS_ROUTE]: {
    authOnly: true,
    element: <div />,
    path: getRouteMarketingLinkDetails(':id'),
  },
};

// this func for mapping our routes, and return only route with noHeaderFooter
export const pathNoHeaderFooter = () => {
  const locals = Object.values(routeConfigs)
    .filter(path => path.noHeaderFooter)
    .map(p => p.path);
  return locals;
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ILogsCSVResponse } from '../types/SCVFormat';
import { downloadCsvLogs } from '../utils/downloadCsvLogs';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const getLogsCsvById = createAsyncThunk<
  ILogsCSVResponse,
  number,
  ThunkConfig<string>
>('getLogsCsvById', async (userId, thunkApi) => {
  const { extra, rejectWithValue, dispatch } = thunkApi;

  try {
    const response = await extra.api.get<ILogsCSVResponse>(
      urls.csv.getUserLogs(userId),
    );

    if (!response.data) throw new Error('error csv');

    downloadCsvLogs(response.data);

    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    if (
      customError?.response?.data.errorCode !== 'errors.admin.user.log.notFound'
    )
      showSnackbar(customError?.response?.data.message, 'warning');
    return rejectWithValue(customError?.response?.data?.errorCode);
  }
});

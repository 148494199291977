import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from '@/app/providers/StoreProvider';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import urls from '@/shared/const/urls';

interface ISwitchSubscriptionData {
  productId: number;
  customerId: number;
  subscriptionId: number;
}

export const switchSubscriptionRequest = createAsyncThunk<
  null,
  ISwitchSubscriptionData,
  ThunkConfig<string>
>('profile/switchSubscription', async (switchSubscriptionData, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.post<null>(
      urls.products.switchSubscription(
        switchSubscriptionData.customerId,
        switchSubscriptionData.subscriptionId,
      ),
      { productId: switchSubscriptionData.productId },
    );

    if (!response.data) throw new Error();

    return response.data;
  } catch (e) {
    console.log(e);
    return rejectWithValue('error');
  }
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProfile } from '../types/profile';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const getCustomerProfileRequest = createAsyncThunk<
  IProfile,
  number,
  ThunkConfig<string>
>('profile/getCustomerProfileRequest', async (userId, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<IProfile>(
      urls.customer.getOrEditCustomerProfile(userId),
    );

    if (!response.data) throw new Error(response as any);

    return response.data;
  } catch (e: any) {
    console.log(e, 'getCustomerProfileRequest');
    showSnackbar(e?.response?.data?.message, 'error');

    return rejectWithValue('error');
  }
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILogsCSVResponse } from '../types/SCVFormat';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const fetchCustomerActivityLogs = createAsyncThunk<
  ILogsCSVResponse,
  number,
  ThunkConfig<string>
>('fetchCustomerActivityLogs', async (userId, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<ILogsCSVResponse>(
      urls.csv.getUserLogs(userId),
    );

    if (!response.data) throw new Error('error');

    return response.data;
  } catch (error) {
    console.log(error);

    return rejectWithValue('error');
  }
});

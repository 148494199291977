import { StateSchema } from '@/app/providers/StoreProvider';

export const getProductIsLoading = (state: StateSchema) =>
  state.product.isLoading;
export const getErrorProduct = (state: StateSchema) => state.product.error;

export const getAllUserProducts = (state: StateSchema) =>
  state.product.customersProducts;

export const getAllProducts = (state: StateSchema) => state.product.allProducts;
export const getActiveProducts = (state: StateSchema) =>
  state.product.activeProducts;

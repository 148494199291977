import { FC, memo, Suspense, useState } from 'react';
import cls from './modalEditUserDetails.module.css';
import { classNames } from '@/shared/lib/classNames/classNames';
import { Button } from '@/shared/ui/Buttons';
import { Input } from '@/shared/ui/Inputs';
import { Spinner } from '@/shared/ui/Loaders';
import { Modal } from '@/shared/ui/Modal';
import { Select } from '@/shared/ui/Popovers';
import { HStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text/Text';

export interface IModalEditUserDetailsProps {
  className?: string;
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const ModalEditUserDetails: FC<IModalEditUserDetailsProps> = memo(
  props => {
    const { className, isOpen, onClose, onSubmit } = props;
    const [lang, setLang] = useState(['English']);
    const [timezone, setTimezone] = useState(['GMT+3']);

    const langs = [
      { content: 'English', value: 'English' },
      { content: 'Russian', value: 'Russian' },
    ];
    const onChangeLanguage = (val: any) => {
      setLang(val);
      console.log(val);
    };

    const timezones = [
      { content: 'GMT+3', value: 'GMT+3' },
      { content: 'GMT+2', value: 'GMT+2' },
      { content: 'GMT+1', value: 'GMT+1' },
    ];
    const onChangeTimezone = (val: string[]) => {
      setTimezone(val);
      console.log(val);
    };

    return (
      <Modal
        className={classNames(cls.inner, {}, [className])}
        isOpen={isOpen}
        onClose={onClose}
      >
        <Suspense fallback={<Spinner />}>
          <Text align='center' variant='H2'>
            {'Edit User Details'}
          </Text>
          <Input
            isLabelVertical
            wrapperClass={cls['wrapper-input']}
            className={cls.input}
            label='Name'
            placeholder='John Doe'
          />
          <Input
            isLabelVertical
            wrapperClass={cls['wrapper-input']}
            className={cls.input}
            label='Email'
            placeholder='john@gmail.com'
          />
          <Input
            isLabelVertical
            wrapperClass={cls['wrapper-input']}
            className={cls.input}
            label='Contact'
            placeholder='+123 456 678 900'
          />
          <Select
            label='Language'
            items={langs}
            wrapperClass={cls['wrapper-select']}
            className={cls['inner-select']}
            value={lang}
            onChange={value => onChangeLanguage(value as string[])}
          />
          <Select
            label='Timezone'
            items={timezones}
            wrapperClass={cls['wrapper-select']}
            className={cls['inner-select']}
            value={timezone}
            onChange={value => onChangeTimezone(value as string[])}
          />
          <HStack max justify='end' gap={1} className={cls.buttons}>
            <Button
              size='large'
              typeButton='primary'
              text='Submit'
              onClick={onSubmit}
            />
            <Button
              size='large'
              typeButton='ghost'
              text='Cancel'
              onClick={onClose}
            />
          </HStack>
        </Suspense>
      </Modal>
    );
  },
);

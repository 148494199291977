import { memo, Suspense, useCallback } from 'react';
import { Route, Routes } from 'react-router-dom';
import { RequireAuth } from './RequireAuth';
import { RequireRole } from './RequireRole';
import { routeConfigs } from '../model/routeConfig';
import { AppRoutesProps } from '@/shared/types/router';
import { SiteSpinner } from '@/shared/ui/Loaders';

const AppRouters = () => {
  const renderWithWrapper = useCallback((route: AppRoutesProps) => {
    const element = (
      <Suspense
        fallback={
          <div>
            <SiteSpinner />
          </div>
        }
      >
        {route.element}
      </Suspense>
    );

    return (
      <Route
        key={route.path}
        path={route.path}
        element={
          route.authOnly ? (
            <RequireAuth>
              <RequireRole>{element}</RequireRole>
            </RequireAuth>
          ) : (
            element
          )
        }
      />
    );
  }, []);

  return <Routes>{Object.values(routeConfigs).map(renderWithWrapper)}</Routes>;
};

export default memo(AppRouters);

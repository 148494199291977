import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IResponseMessages } from '../types/message';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const serviceAllMessagesMarketing = createAsyncThunk<
  IResponseMessages,
  void,
  ThunkConfig<string>
>('messages/serviceAllMessagesMarketing', async (_, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  const {
    messages: {
      marketing: { forRequest },
    },
  } = getState();

  try {
    const response = await extra.api.post<IResponseMessages>(
      urls.message.getAllMessagesMarketing,
      { ...forRequest, size: forRequest.step },
    );

    if (!response.data) throw new Error('error serviceAllMessagesMarketing');

    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.message, 'error');
    return rejectWithValue(customError.message);
  }
});

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { editCustomerProfileRequest } from '../services/editCustomerProfileRequest';
import { getCustomerProfileRequest } from '../services/getCustomerProfileRequest';
import { IProfile, ProfileSchema } from '../types/profile';

const initialState: ProfileSchema = {
  isLoading: false,
  profile: {
    email: '',
    id: 0,
    language: '',
    name: '',
    paymentService: 'Stripe',
    phoneNumber: '',
    status: 'new',
    telegramId: '',
    timezone: '',
  },
};

export const profileSlice = createSlice({
  extraReducers: builder => {
    builder
      .addCase(getCustomerProfileRequest.pending, state => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(
        getCustomerProfileRequest.fulfilled,
        (state, { payload }: PayloadAction<IProfile>) => {
          state.isLoading = false;
          state.profile = payload;
        },
      )
      .addCase(getCustomerProfileRequest.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(editCustomerProfileRequest.pending, state => {
        state.error = undefined;
      })
      .addCase(
        editCustomerProfileRequest.fulfilled,
        (state, { payload }: PayloadAction<IProfile>) => {
          state.profile = payload;
        },
      )
      .addCase(editCustomerProfileRequest.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
  initialState,
  name: 'profile',
  reducers: {
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
  },
});

export const { setIsLoading } = profileSlice.actions;
export const { reducer: profileReducer } = profileSlice;

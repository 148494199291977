import { FC, useCallback, useMemo } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import cls from './mainHeader.module.scss';
import { getUserRole, logoutQuery, getUserName } from '@/entities/User';
import {
  AppRoutes,
  getRouteAllMessagesMarketing,
  getRouteAllMessagesUsers,
  getRouteCustomers,
  getRouteDecisions,
  getRouteLogin,
  getRoutePartners,
} from '@/shared/const/router';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { Button } from '@/shared/ui/Buttons';
import { Dropdown } from '@/shared/ui/Popovers';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text/Text';

interface MainHeaderProps {
  className?: string;
}

export const MainHeader: FC<MainHeaderProps> = props => {
  const { className } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userRole = useAppSelector(getUserRole);
  const name = useAppSelector(getUserName);

  const isManager = userRole === 'MANAGER';
  const isPartner = userRole === 'PARTNER_MANAGER';
  const isAdmin = userRole === 'ADMIN';

  const links = useMemo(
    () =>
      [
        ...[isPartner ? {} : { title: 'Users', to: getRouteCustomers() }],
        ...[
          isManager || isPartner
            ? {}
            : { title: 'Decisions', to: getRouteDecisions() },
        ],
        ...[
          isManager || isPartner
            ? {}
            : { title: 'All Messages', to: getRouteAllMessagesUsers() },
        ],
        ...[
          isPartner || isAdmin
            ? { title: 'Partners', to: getRoutePartners() }
            : {},
        ],
      ] as { title: string; to: AppRoutes }[],
    [isManager, isPartner, isAdmin],
  );

  const linksList = useMemo(
    () =>
      links.map(item =>
        item.title === 'All Messages' ? (
          <Dropdown
            key={item.title}
            minWidth='3-x-l'
            trigger={<Text variant='BodyL'>{'All Messages'}</Text>}
            items={[
              { content: 'Users', href: getRouteAllMessagesUsers() },
              { content: 'Marketing', href: getRouteAllMessagesMarketing() },
            ]}
          />
        ) : (
          <NavLink
            key={item.title}
            to={item.to}
            className={({ isActive }) => (isActive ? cls.active : '')}
          >
            {item.title}
          </NavLink>
        ),
      ),
    [links],
  );

  const toLogout = useCallback(() => {
    dispatch(logoutQuery());
    navigate(getRouteLogin());
  }, [dispatch, navigate]);

  return (
    <header className={cls.mainHeader}>
      <HStack justify='between' className={cls.content}>
        <HStack gap={1} className={cls.menu}>
          {linksList}
        </HStack>
        <HStack gap={1}>
          <VStack>
            <p className={cls.username}>{name}</p>
            <p className={cls.role}>{userRole}</p>
          </VStack>
          <HStack gap={1}>
            <div className={cls.avatar} />
            <Button typeButton='ghost' size='small' onClick={toLogout}>
              {'Logout'}
            </Button>
          </HStack>
        </HStack>
      </HStack>
    </header>
  );
};

import { FC, memo, Suspense, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import cls from './modalSubscribe.module.css';
import {
  fetchActiveProducts,
  getActiveProducts,
  serviceAllCustomersProducts,
} from '@/entities/Product';
import { subscribeCustomerRequest } from '@/entities/Profile';
import { FiltersDatePicker, IDateValue } from '@/features/FiltersDatePicker';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { Button } from '@/shared/ui/Buttons';
import { Spinner } from '@/shared/ui/Loaders';
import { Modal } from '@/shared/ui/Modal';
import { Select } from '@/shared/ui/Popovers/ui/Select/Select';
import { HStack, VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text/Text';

export interface IModalSubscribeProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalSubscribe: FC<IModalSubscribeProps> = memo(props => {
  const { className, isOpen, onClose } = props;
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const activeProducts = useAppSelector(getActiveProducts);

  const activeProductsArray = useMemo(
    () =>
      activeProducts
        ? activeProducts?.map(item => {
            const content = item.title;
            const value = `${item.id} ${item.title}`;
            return { content, value };
          })
        : [],
    [activeProducts],
  );

  const [filterDate, setFilterDate] = useState<IDateValue>(null);
  const [subscription, setSubscription] = useState('');

  const onSubmit = async () => {
    try {
      const req = await dispatch(
        subscribeCustomerRequest({
          customerId: Number(id),
          period: filterDate,
          productId: Number(subscription.slice(0, 2)),
        }),
      );
      if (req.meta.requestStatus === 'fulfilled') {
        await dispatch(serviceAllCustomersProducts(Number(id)));
        onClose();
      }
    } catch (e) {
      console.log('Submit subscribe error: ', e);
    }
  };

  const filterDateForDatePicker = useMemo(
    () => (filterDate ? (filterDate as unknown as IDateValue) : null),
    [filterDate],
  );

  const onChangePeriod = (value: IDateValue) => {
    setFilterDate(value);
  };

  const onChangeSubscription = (val: string) => setSubscription(val);

  useEffect(() => {
    dispatch(fetchActiveProducts());
  }, [dispatch]);

  return (
    <Modal
      className={classNames(cls.inner, {}, [className])}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Suspense fallback={<Spinner />}>
        <Text align='center' variant='H2'>
          {'Subscribe'}
        </Text>
        <VStack height>
          <FiltersDatePicker
            className={cls.datePicker}
            date={filterDateForDatePicker}
            onChangeDate={onChangePeriod}
          />
          <Select
            label='Products'
            wrapperClass={cls['wrapper-select']}
            className={cls['inner-select']}
            value={subscription.replace(/[0-9]/g, '')}
            items={activeProductsArray}
            onChange={value => onChangeSubscription(value as string)}
          />
        </VStack>
        <HStack max justify='end' gap={1} className={cls.buttons}>
          <Button
            size='large'
            typeButton='primary'
            text='Submit'
            disabled={filterDate === null}
            onClick={onSubmit}
          />
          <Button
            size='large'
            typeButton='ghost'
            text='Cancel'
            onClick={onClose}
          />
        </HStack>
      </Suspense>
    </Modal>
  );
});

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IResponseGetAllPaymentsInvitedUser } from '../types/partner';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const servicePaymentsInvitedUsers = createAsyncThunk<
  IResponseGetAllPaymentsInvitedUser,
  string,
  ThunkConfig<string>
>('partner/servicePaymentsInvitedUsers', async (partnerId, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  const {
    partners: { forRequestAllPaymentsInvitedUsers },
  } = getState();

  try {
    const response = await extra.api.post<IResponseGetAllPaymentsInvitedUser>(
      urls.partners.getAllPaymentsInvitedUsers(partnerId),
      {
        ...forRequestAllPaymentsInvitedUsers,
        size: forRequestAllPaymentsInvitedUsers.step,
      },
    );
    if (!response.data) throw new Error('An error occurred');
    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.partner, 'error');
    return rejectWithValue(customError.message);
  }
});

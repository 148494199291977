export enum AppRoutes {
  LOGIN_ROUTE = '/',
  DECISIONS_ROUTE = '/decisions',
  CUSTOMERS_ROUTE = '/customers',
  ALL_USERS_MESSAGES_ROUTE = '/messages/users',
  ALL_MARKETING_MESSAGES_ROUTE = '/messages/marketing',
  MESSAGE_DETAILS_ROUTE = '/message_details',
  SEND_MESSAGE_ROUTE = '/all_customers/send_message',
  SEND_MESSAGE_LINKS_ROUTE = '/all_links/send_message',
  CUSTOMER_BY_ID = '/customer',
  PARTNERS_ROUTE = '/partners',
  PARTNER_DETAILS_ROUTE = '/partner_details',
  MARKETING_LINK_DETAILS_ROUTE = '/marketing_link_details',

  // last
  NOT_FOUND = '/not_found',
  MODALS = '/modals',
}

export const getRouteLogin = () => AppRoutes.LOGIN_ROUTE;
export const getRouteDecisions = () => AppRoutes.DECISIONS_ROUTE;
export const getRouteCustomers = () => AppRoutes.CUSTOMERS_ROUTE;
export const getRouteAllMessagesUsers = () =>
  AppRoutes.ALL_USERS_MESSAGES_ROUTE;
export const getRouteAllMessagesMarketing = () =>
  AppRoutes.ALL_MARKETING_MESSAGES_ROUTE;
export const getRouteMessagesDetails = (id: string) =>
  `${AppRoutes.MESSAGE_DETAILS_ROUTE}/${id}`;
export const getRouteSendMessage = () => AppRoutes.SEND_MESSAGE_ROUTE;
export const getRouteSendMessageLinks = () =>
  AppRoutes.SEND_MESSAGE_LINKS_ROUTE;
export const getRouteCustomer = (id: string) =>
  `${AppRoutes.CUSTOMER_BY_ID}/${id}`;
export const getRoutePartners = () => AppRoutes.PARTNERS_ROUTE;
export const getRoutePartnersDetails = (id: string) =>
  `${AppRoutes.PARTNER_DETAILS_ROUTE}/${id}`;
export const getRouteMarketingLinkDetails = (id: string) =>
  `${AppRoutes.MARKETING_LINK_DETAILS_ROUTE}/${id}`;

export const allRoutes = {
  getRouteAllMessagesMarketing,
  getRouteAllMessagesUsers,
  getRouteCustomer,
  getRouteCustomers,
  getRouteDecisions,
  getRouteLogin,
  getRouteMarketingLinkDetails,
  getRouteMessagesDetails,
  getRoutePartners,
  getRoutePartnersDetails,
  getRouteSendMessage,
  getRouteSendMessageLinks,
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { IDecision, IDecisionsReq } from '../types/decisions';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const fetchDecisions = createAsyncThunk<
  IDecision,
  IDecisionsReq,
  ThunkConfig<string>
>('decisions/decisions', async (_, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.post<IDecision>(
      urls.decision.createDecisions,
    );
    if (!response.data) throw new Error();

    return response.data;
  } catch (error) {
    console.log(error);
    return rejectWithValue('error load decisions');
  }
});

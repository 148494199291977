import { FC, HTMLAttributes, memo, useMemo } from 'react';
import './siteSpinner.scss';

interface ISpinnerProps extends HTMLAttributes<SVGElement> {
  color?: string;
  enabled?: boolean;
  align?: string;
  size?: number | string;
  speed?: number;
}

export const SiteSpinner: FC<ISpinnerProps> = memo(props => {
  const {
    color = '#282828',
    speed = 100,
    enabled = true,
    align,
    size = 48,
    style,
  } = props;

  const normalizeSize = useMemo(
    () =>
      parseFloat(size.toString()).toString() === size.toString()
        ? `${size}px`
        : size.toString(),
    [size],
  );

  const currentStyle = {
    alignSelf: align,
    color,
    overflow: 'visible',
    width: normalizeSize,
    ...style,
  };

  if (!enabled) return null;
  return (
    <div className='lds-spinner-wrapper'>
      <div className='lds-spinner' style={currentStyle}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
});

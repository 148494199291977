import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { IPartner } from '../types/partner';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const serviceGetPartner = createAsyncThunk<
  IPartner,
  string,
  ThunkConfig<string>
>('partner/serviceGetPartner', async (partnerId, thunkApi) => {
  const { dispatch, extra, getState, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.get<IPartner>(
      urls.partners.getPartner(partnerId),
    );

    if (!response.data) throw new Error('error serviceGetPartner');

    return response.data;
  } catch (error) {
    console.log(error);
    const customError = error as AxiosError<any>;
    showSnackbar(customError.response?.data.message, 'error');
    return rejectWithValue(customError.message);
  }
});

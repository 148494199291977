import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { servicePaymentsInvitedUsers } from './servicePaymentsInvitedUsers';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';
import { showSnackbar } from '@/shared/ui/Snackbars/Snackbars';

export const servicePostApproveCashout = createAsyncThunk<
  void,
  {
    partnerId: string;
    requestId: string;
    invoiceDetails: string;
  },
  ThunkConfig<string>
>(
  'partner/servicePostApproveCashout',
  async ({ partnerId, requestId, invoiceDetails }, thunkApi) => {
    const { dispatch, extra, rejectWithValue } = thunkApi;

    try {
      const response = await extra.api.post(
        urls.partners.postApproveCashout(partnerId, requestId),
        { invoiceDetails },
      );

      showSnackbar('Payment was successfully approved', 'success');

      dispatch(servicePaymentsInvitedUsers(partnerId));

      return response.data;
    } catch (error) {
      console.log(error);
      const customError = error as AxiosError<any>;
      showSnackbar(customError.response?.data.message, 'error');
      return rejectWithValue(customError.message);
    }
  },
);

const urls = {
  csv: {
    getUserLogs: (userId: number) => `admin/users/${userId}/logs`,
  },
  customer: {
    blockCustomer: (customerId: string) => `admin/users/${customerId}/suspend`,
    getAllCustomers: 'admin/users',
    getOrEditCustomerProfile: (customerId: number) =>
      `/admin/users/${customerId}`,
    subscribeCustomer: (customerId: number) =>
      `/admin/users/${customerId}/subscriptions`,
  },
  decision: {
    createDecisions: '/admin/decision-stats',
  },
  marketingLinks: {
    getAllMarketingLinks: '/admin/marketing/links/stats',
    getMarketingLink: (marketingLinkId: string) =>
      `/admin/marketing/${marketingLinkId}`,
    sendMessageMarketingLink: (marketingLinkId: number) =>
      `/admin/marketing/${marketingLinkId}/send`,
  },
  message: {
    getAllMessages: '/admin/messages',
    getAllMessagesMarketing: '/admin/messages/marketing/getMessages',
    getMessage: (messageId: string) => `/admin/messages/${messageId}`,
    getUsersInMessages: 'admin/messages/users/pick',
    sendMessage: (messageId: number) => `/admin/messages/${messageId}/send`,
    sendMessageMarketingLink: '/admin/messages/marketing/sendMessage',
  },
  partners: {
    getAllInvitedUsers: (partnerId: string) =>
      `/admin/partners/${partnerId}/users`,
    getAllPartners: '/admin/partners',
    getAllPaymentsInvitedUsers: (partnerId: string) =>
      `/admin/partners/${partnerId}/requests`,
    getPartner: (partnerId: string) => `/admin/partners/${partnerId}`,
    postApproveCashout: (partnerId: string, requestId: string) =>
      `/admin/partners/${partnerId}/requests/${requestId}/approve`,
  },
  products: {
    changePeriod: (customerId: number, subscriptionId: number) =>
      `/admin/users/${customerId}/subscriptions/${subscriptionId}/period`,
    ddCustomerProduct: (customerId: number, subscriptionId: number) =>
      `/admin/users/${customerId}/subscriptions/${subscriptionId}/direct-debit/cancel`,
    getAllowedProducts: (customerId: number) =>
      `/admin/products/${customerId}/allowed`,
    getCustomerProduct: (customerId: number) =>
      `/admin/users/${customerId}/subscriptions`,
    getProducts: '/admin/products',
    getProductsActive: '/admin/products/active',
    suspendCustomerProduct: (customerId: number, subscriptionId: number) =>
      `/admin/users/${customerId}/subscriptions/${subscriptionId}/suspend`,
    switchSubscription: (customerId: number, subscriptionId: number) =>
      `/admin/users/${customerId}/subscriptions/${subscriptionId}/change`,
  },
  transaction: {
    getCustomerTransactions: (customerId: number) =>
      `/admin/users/${customerId}/transactions`,
  },
  user: {
    getRefreshToken: 'admin/auth/refresh',
    login: 'admin/auth/login',
    logout: 'admin/auth/logout',
  },
};

export default urls;

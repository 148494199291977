import { Listbox } from '@headlessui/react';
import { FC, Fragment, ReactNode, memo, useCallback, useMemo } from 'react';
import cls from './select.module.scss';
import popupCls from '../../../../styles/popups.module.scss';
import { Icon } from '../../../Icon';
import clsInput from '../../../Inputs/model/styles/inputStyles.module.scss';
import { HStack, VStack } from '../../../Stack';
import DownArrow from '@/shared/assets/svg/DownArrow.svg';
import { classNames } from '@/shared/lib/classNames/classNames';
import { mapDirectionClass } from '@/shared/styles/const';
import { DropDownDirection } from '@/shared/types/ui';

export interface SelectItem {
  content: string;
  // content: ReactNode | string;
  disabled?: boolean;
  value: string;
}

interface ISelectProps {
  className?: string;
  classNameInput?: string;
  wrapperClass?: string;
  classNamePopup?: string;
  defaultValue?: string;
  direction?: DropDownDirection;
  items: SelectItem[];
  label?: string;
  onChange: (value: string[] | string) => void;
  readonly?: boolean;
  value?: string[] | string;
  placeholder?: string;
  fullWidth?: boolean;
  multiple?: boolean;
  triggerContent?: ReactNode;
  size?: 'large' | 'small';
  minWidth?: string | number;
}

export const Select: FC<ISelectProps> = memo(props => {
  const {
    className,
    classNameInput,
    classNamePopup,
    wrapperClass,
    defaultValue,
    direction = 'bottom left',
    items,
    label,
    multiple,
    onChange,
    readonly,
    value,
    placeholder,
    fullWidth,
    triggerContent,
    size = 'large',
    minWidth = '8',
  } = props;

  const currentClassNamePopup = useMemo(
    () =>
      classNames(popupCls.popup, { [clsInput['full-width']]: fullWidth }, [
        className,
      ]),
    [className, fullWidth],
  );

  const classNameInputSelect = useMemo(
    () =>
      classNames(
        clsInput.input,
        {
          [cls.placeholder]: !!placeholder,
          [clsInput['full-width']]: fullWidth,
        },
        [cls['input-select'], classNameInput],
      ),
    [classNameInput, fullWidth, placeholder],
  );

  const classNameSelect = useMemo(
    () =>
      classNames(popupCls.menu, {}, [
        mapDirectionClass[direction],
        classNamePopup,
      ]),
    [classNamePopup, direction],
  );

  const optionList = useMemo(
    () =>
      items.map(item => (
        <Listbox.Option
          key={item.value}
          value={item.value}
          as={Fragment}
          disabled={item.disabled}
        >
          {({ active, selected }) => (
            <li
              className={classNames(
                popupCls.item,
                {
                  [popupCls.active]: active,
                  [popupCls.disabled]: item.disabled,
                  [popupCls.selected]: selected,
                },
                [popupCls[size]],
              )}
            >
              {item.content}
            </li>
          )}
        </Listbox.Option>
      )),
    [items, size],
  );

  const removeProductId = useMemo(
    () =>
      label === 'Products' && typeof value === 'object'
        ? value?.map(s => s.slice(2))
        : [value],
    [label, value],
  );

  const viewValue =
    typeof value === 'object'
      ? (removeProductId && removeProductId?.join(', ')) || value?.join(', ')
      : value;

  const currentTriggerContent = useCallback(
    (open: boolean) =>
      triggerContent || (
        <HStack
          gap='0.5'
          justify='between'
          className={classNameInputSelect}
          style={{ minWidth: `${minWidth}rem` }}
        >
          {viewValue}
          <Icon Svg={DownArrow} />
        </HStack>
      ),
    [classNameInputSelect, minWidth, viewValue, triggerContent],
  );

  return (
    <VStack gap='0.5' className={wrapperClass}>
      {!!label && <span className={cls.label}>{label}</span>}
      <Listbox
        as='div'
        multiple={multiple}
        disabled={readonly}
        value={value}
        className={currentClassNamePopup}
        onChange={onChange}
      >
        {({ open }) => (
          <>
            <Listbox.Button as='div' className={popupCls.trigger}>
              {currentTriggerContent(open)}
            </Listbox.Button>
            <Listbox.Options
              className={classNameSelect}
              style={{ minWidth: `${minWidth}rem` }}
            >
              {optionList}
            </Listbox.Options>
          </>
        )}
      </Listbox>
    </VStack>
  );
});

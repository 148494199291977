import { IDecision, IDecisionsReq } from '../model/types/decisions';
import { rtkApi } from '@/shared/api/rtkApi';
import urls from '@/shared/const/urls';

const decisionsApi = rtkApi.injectEndpoints({
  endpoints: build => ({
    createDecisions: build.mutation<IDecision, IDecisionsReq>({
      query: params => ({
        body: { ...params },
        method: 'POST',
        url: urls.decision.createDecisions,
      }),
    }),
  }),
});

export const useDecisions = decisionsApi.useCreateDecisionsMutation;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { ITransaction } from '../types/transaction';
import { ThunkConfig } from '@/app/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const getCustomerTransactionsRequest = createAsyncThunk<
  ITransaction[],
  number,
  ThunkConfig<string>
>(
  'transaction/getCustomerTransactionsRequest',
  async (customerId, thunkApi) => {
    const { extra, rejectWithValue } = thunkApi;

    try {
      const response = await extra.api.get<{ data: ITransaction[] }>(
        urls.transaction.getCustomerTransactions(customerId),
      );

      if (!response.data) throw new Error();

      return response.data.data;
    } catch (e) {
      console.log(e);
      return rejectWithValue('error');
    }
  },
);

import { Navigate, useLocation } from 'react-router-dom';

export const RequireAuth = (props: { children: JSX.Element }) => {
  const { children } = props;

  const isAuth = localStorage.getItem('USER_AUTH_TOKEN');
  const location = useLocation();

  if (!isAuth) return <Navigate replace to={'/'} state={{ from: location }} />;

  return children;
};
